import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	oopsNotFound: {
		maxWidth: 450,
		paddingTop: 30,
		textAlign: 'center',
	},
	oopsNotFoundContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		padding: '75px 20px 75px',
	},
});
