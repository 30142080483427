import * as Api from '@ViewModels';
import * as h from 'history';
import { WebServiceHelper } from './webServiceHelper';

export class Landing {
	private mShortCode = '';
	private webServiceHelper: WebServiceHelper;

	constructor(location: h.Location) {
		this.webServiceHelper = new WebServiceHelper();

		this.mShortCode = location.pathname.split('/')?.[2];
	}

	public async fetchMeetings() {
		return this.callWebServiceAsync(`scheduler/${this.mShortCode}/landing`, 'GET');
	}

	private async callWebServiceAsync(url: string, method: Api.HTTPMethod, body?: any) {
		try {
			const result = await this.webServiceHelper.fetch<Api.IMeetingConfigLanding>(url, method, body);
			if (result.success) {
				return result.value;
			} else {
				throw Api.asApiError(result);
			}
		} catch (err) {
			throw Api.asApiError(err);
		}
	}
}
