import { StyleSheet } from 'aphrodite';
import { background, dashboardNavBackground, header, mention, titles } from '../../web/styles/colors';
import { BreakPoints, truncateTextStyle } from '../../web/styles/styles';

export const MobileBreakpoint = 720;

export const styleSheet = StyleSheet.create({
	background: {
		alignItems: 'flex-start',
		background: dashboardNavBackground,
		display: 'flex',
		justifyContent: 'center',
		minHeight: '100vh',
		padding: '75px 0 40px',
		width: '100%',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			background: '#fff',
			paddingTop: 0,
		},
	},
	container: {
		background: '#fff',
		borderRadius: 6,
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 920,
		position: 'relative',
		width: '90%',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			borderRadius: 0,
			width: '100%',
		},
	},
	errorIcon: {
		display: 'block',
		margin: 'auto',
	},
	errorText: {
		color: '#fff',
		fontSize: 18,
		marginTop: 12,
		textAlign: 'center',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			color: '#000',
		},
	},
	header: {
		background,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		boxShadow: `0 0 6px 0 rgba(139, 139, 139, 0.20) inset`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minHeight: 180,
		position: 'relative',
		[BreakPoints.forMaxWidth(768)]: {
			flexDirection: 'column',
			padding: '0 30px',
		},
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			minHeight: 170,
		},
	},
	headerCol: {
		flexBasis: '50%',
		padding: '45px 30px 0',
		position: 'relative',
		textAlign: 'center',
		[BreakPoints.forMaxWidth(768)]: {
			padding: '15px 30px',
		},
	},
	headerColLeft: {
		flex: '1 0 50%',
	},
	headerColRight: {
		textAlign: 'left',
		[BreakPoints.forMaxWidth(768)]: {
			alignItems: 'center',
			borderTop: `1px solid ${mention}`,
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
		},
	},
	hide: {
		display: 'none',
	},
	inputLabel: {
		colors: titles,
		display: 'block',
		fontSize: '14px',
		paddingBottom: 4,
	},
	label: {
		...truncateTextStyle,
		color: header,
		fontSize: '14px',
		margin: 0,
	},
	labelAddress: {
		overflow: 'initial',
		textOverflow: 'initial',
		whiteSpace: 'initial',
		wordBreak: 'break-word',
		maxWidth: '75%',
	},
	pill: {
		background: mention,
		borderRadius: '50px',
		padding: '9px 19px',
	},
	text: {
		fontSize: '16px',
		margin: 0,
	},
});
