import { StyleSheet } from 'aphrodite';
import { BreakPoints, truncateTextStyle } from '../../web/styles/styles';
import { MobileBreakpoint } from '../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {},
	errorMessage: {
		margin: 0,
	},
	labelIcon: {
		left: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)',
	},
	labelWithIcon: {
		marginTop: 10,
		padding: '1px 0 0 25px',
		position: 'relative',
		[BreakPoints.forMaxWidth(768)]: {
			display: 'inline-block',
			width: 'auto',
		},
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			marginTop: 5,
		},
	},
	meetingName: {
		...truncateTextStyle,
		fontSize: '18px',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			fontSize: '14px',
		},
	},
});
