import * as Api from '@ViewModels';
import { Landing } from './viewmodels/landing';
import { Meeting } from './viewmodels/meeting';

export interface IMeetingInfo {
	duration: string;
	location: string;
	name: string;
	owner: string;
	ownerCompany: string;
}

export interface IErrorContext {
	setError: React.Dispatch<React.SetStateAction<Api.IOperationResultNoValue>>;
}

export interface IMeetingContext {
	meetingVm: Meeting;
}

export interface ILandingContext {
	landingVm: Landing;
}

export interface IAvailabilitySlot {
	time: moment.Moment;
	priority: number;
	ids: string[];
}

export interface ITeamMemberAvailabilities {
	availabilities: (Api.ISchedulerDailyIntervals[] | null)[];
	config: Api.IMeetingConfigPriority;
	success: boolean;
}

export enum UserInput {
	AlternativeTime = 'alternativeTime',
	Comments = 'comments',
	CompanyName = 'companyName',
	EmailAddress = 'emailAddress',
	FirstName = 'firstName',
	LastName = 'lastName',
	Location = 'location',
	TextOptIn = 'textOptIn',
	PhoneNumber = 'phoneNumber',
	MeetingName = 'meetingName',
	CustomField = 'customField',
}

export interface IUserInputFieldData {
	id: UserInput;
	maxLength?: number;
	required: boolean;
	text: string;
	type?: 'text' | 'textarea';
	value: string;
}

export interface ISetStartDateRequest {
	startDate: string;
	configIds: string[];
}
