import { header, urgentDate } from '../../web/styles/colors';
import { CssSelectors } from '../../web/styles/styles';
import { styleSheet as pageStyles } from '../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addMeetingGuest: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allDescendants(
			{
				height: 16,
			},
			'svg'
		),
	},
	addMeetingGuestInput: {
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
	},
	container: {
		...CssSelectors.allChildren(
			{
				paddingTop: 0,
			},
			'button'
		),
	},
	error: {
		color: urgentDate,
		fontSize: '12px',
		fontStyle: 'italic',
		paddingBottom: 5,
	},
	inputContainer: {
		display: 'flex',
		marginBottom: 5,
		...CssSelectors.allChildren(
			{
				borderBottomRightRadius: 0,
				borderRight: 'none',
				borderTopRightRadius: 0,
				flexGrow: 1,
			},
			'.text-input'
		),
		...CssSelectors.allChildren(
			{
				':disabled': {
					opacity: 0.5,
				},
				borderBottomLeftRadius: 0,
				borderTopLeftRadius: 0,
				minWidth: 62,
				padding: '10px',
			},
			'button'
		),
	},
	meetingGuest: {
		color: header,
		display: 'flex',
		fontStyle: 'italic',
		justifyContent: 'flex-start',
		...CssSelectors.allChildren(
			{
				maxWidth: 20,
				paddingTop: 0,
			},
			'button'
		),
	},
	meetingGuestsContainer: {
		marginBottom: 10,
		maxHeight: 100,
		overflow: 'auto',
	},
	text: {
		...pageStyles.text,
		fontSize: '14px',
		paddingBottom: 5,
	},
});
