import * as Api from '@ViewModels';
import { createContext, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { IMeetingContext } from '../types';
import { Meeting } from '../viewmodels/meeting';

// @ts-ignore
const MeetingContext = createContext<IMeetingContext>(null);

export const MeetingProvider: React.FC = ({ children }) => {
	const location = useLocation();
	// @ts-ignore
	const [meeting, setMeeting] = useState<Api.ISchedulerResponse>(undefined);
	return (
		<MeetingContext.Provider value={{ meetingVm: new Meeting(meeting, setMeeting, location) }}>
			{children}
		</MeetingContext.Provider>
	);
};

export const useMeeting = () => {
	const context = React.useContext(MeetingContext);
	if (!context) {
		throw new Error('useMeeting can only be used within the <ScheduleMeetingApp /> component');
	}
	return context;
};
