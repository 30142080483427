import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useDebounceValue } from '../../hooks/useDebounceValue';
import { useEventLogging } from '../../models/Logging';
import { isValidEmail, isValidPhone } from '../../models/UiUtils';
import { Checkbox } from '../../web/components/Checkbox';
import { ScheduleMeetingGuests } from '../ScheduleMeetingGuests';
import { ScheduleMeetingInput } from '../ScheduleMeetingInput';
import { useErrorMessage } from '../contexts/errorMessage';
import { useMeeting } from '../contexts/meeting';
import { styleSheet as pageStyles } from '../styles/styles';
import { IUserInputFieldData, UserInput } from '../types';
import { Meeting } from '../viewmodels/meeting';
import { styleSheet } from './styles';

interface IProps {
	includeAltFields?: boolean;
	onInfoChange: (userInfo: Api.ISchedulerContact, meetingGuests?: string[]) => void;
}

interface IState {
	alternativeTime: string;
	alternativeTimeError: string;
	comments: string;
	commentsError: string;
	companyName: string;
	companyNameError: string;
	emailAddress: string;
	emailAddressError: string;
	firstName: string;
	firstNameError: string;
	lastName: string;
	lastNameError: string;
	textOptIn?: boolean;
	phoneNumber: string;
	phoneNumberError: string;
	customProperties?: Record<string, string>;
}

enum UserError {
	AlternativeTimeError = 'alternativeTimeError',
	CommentsError = 'commentsError',
	CompanyNameError = 'companyNameError',
	EmailAddressError = 'emailAddressError',
	FirstNameError = 'firstNameError',
	LastNameError = 'lastNameError',
	PhoneNumberError = 'phoneNumberError',
}

interface IUserAction {
	type: UserInput | UserError;
	payload?: string | boolean;
	customFieldName?: string;
}

// @ts-ignore
const reducer = (state: IState, { type, payload = null }: IUserAction): IState => {
	switch (type) {
		case UserInput.FirstName:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.FirstNameError]: null };
		case UserInput.LastName:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.LastNameError]: null };
		case UserInput.PhoneNumber:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.PhoneNumberError]: null };
		case UserInput.EmailAddress:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.EmailAddressError]: null };
		case UserInput.AlternativeTime:
			return {
				...state,
				[type]: payload as string,
				// @ts-ignore
				[UserError.AlternativeTimeError]: null,
			};
		case UserInput.Comments:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.CommentsError]: null };
		case UserInput.CompanyName:
			// @ts-ignore
			return { ...state, [type]: payload, [UserError.CompanyNameError]: null };
		case UserError.FirstNameError:
		case UserError.LastNameError:
		case UserError.PhoneNumberError:
		case UserError.EmailAddressError:
		case UserError.AlternativeTimeError:
		case UserError.CommentsError:
		case UserInput.TextOptIn:
			return { ...state, [type]: payload as boolean };
		default:
			return state;
	}
};

const initialDefaultState: IState = {
	alternativeTime: '',
	alternativeTimeError: '',
	comments: '',
	commentsError: '',
	companyName: '',
	companyNameError: '',
	emailAddress: '',
	emailAddressError: '',
	firstName: '',
	firstNameError: '',
	lastName: '',
	lastNameError: '',
	phoneNumber: '',
	phoneNumberError: '',
	customProperties: {},
};

const setInitialValues = (meetingVm: Meeting) => (initialArgs: any) => {
	let {
		firstName = '',
		lastName = '',
		phoneNumber = '',
		emailAddress = '',
		alternativeTime = '',
		comments = '',
		companyName = '',
		customProperties = {},
	} = meetingVm.mainParticipant;

	if (!!meetingVm.teamMeetingId && !!meetingVm.contacts?.length) {
		const contact = meetingVm.contacts[0];
		emailAddress = contact?.emailAddress || '';
		firstName = contact?.firstName || '';
		lastName = contact?.lastName || '';
		phoneNumber = contact?.phoneNumber || '';
		companyName = contact?.companyName || '';
		customProperties = contact?.customProperties || {};
	}
	return {
		...initialArgs,
		alternativeTime,
		comments,
		companyName,
		emailAddress,
		firstName,
		lastName,
		phoneNumber,
		customProperties,
	};
};

const CustomFieldLayout = (props: {
	state: IState;
	field: Api.ICustomFieldMetaData;
	onValueChanged: (updatedState: IState) => void;
}) => {
	const [fieldValue, setFieldValue] = React.useState(props.state.customProperties[props.field.fieldName] ?? '');
	const [onValueChanged] = React.useState(() => props.onValueChanged);

	const onCustomFieldInputChanged = (
		_field: UserInput,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFieldValue(e.target.value);
		props.state.customProperties[props.field.fieldName] = e.target.value;
	};

	const debouncedSaveValue = useDebounceValue(fieldValue);

	useEffect(() => {
		props.state.customProperties[props.field.fieldName] = fieldValue;
		onValueChanged(props.state);

		// We only want to trigger this save when the debounced value changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onValueChanged, debouncedSaveValue]);

	return (
		<ScheduleMeetingInput
			key={`customField-${props.field.fieldName}`}
			fieldData={{
				id: UserInput.CustomField,
				maxLength: 500,
				required: props.field.isRequired ?? false,
				text: props.field.fieldLabel,
				type: 'text',
				value: fieldValue,
			}}
			onInputBlur={() => {
				/** noop **/
			}}
			onInputChange={onCustomFieldInputChanged}
		/>
	);
};

export const ScheduleMeetingUserInfo: React.FC<IProps> = ({ includeAltFields, onInfoChange }) => {
	const { meetingVm } = useMeeting();
	const { setError } = useErrorMessage();
	const logger = useEventLogging();

	// Only set a non-null value if the opt-in checkbox will be shown
	const defaultTextOptInValue = meetingVm.showTextOptIn ? false : undefined;
	const initialState = { ...initialDefaultState, ...{ textOptIn: defaultTextOptInValue } };
	const [state, dispatch] = useReducer(reducer, initialState, setInitialValues(meetingVm));
	const [meetingName, setMeetingName] = React.useState(meetingVm.title);

	useEffect(() => {
		const {
			firstNameError,
			lastNameError,
			phoneNumberError,
			emailAddressError,
			alternativeTimeError,
			commentsError,
			companyNameError,
		} = state;
		if (
			!firstNameError &&
			!lastNameError &&
			!phoneNumberError &&
			!emailAddressError &&
			!alternativeTimeError &&
			!commentsError &&
			!companyNameError
		) {
			onInfoChange(state);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const getAnyErrors = useCallback(
		(field: UserInput) => {
			const {
				firstNameError,
				lastNameError,
				phoneNumberError,
				emailAddressError,
				alternativeTimeError,
				commentsError,
				companyNameError,
			} = state;
			switch (field) {
				case UserInput.FirstName:
					return firstNameError;
				case UserInput.LastName:
					return lastNameError;
				case UserInput.PhoneNumber:
					return phoneNumberError;
				case UserInput.EmailAddress:
					return emailAddressError;
				case UserInput.AlternativeTime:
					return alternativeTimeError;
				case UserInput.Comments:
					return commentsError;
				case UserInput.CompanyName:
					return companyNameError;
				default:
					return null;
			}
		},
		[state]
	);

	const userInfoFields = useMemo(() => {
		let fields: IUserInputFieldData[] = [];
		if (meetingVm.requireCompanyOnMainParticipant) {
			fields.push({
				id: UserInput.CompanyName,
				required: true,
				text: 'Company',
				type: 'text',
				value: state.companyName,
			});
		}
		fields = fields.concat([
			{
				id: UserInput.FirstName,
				required: true,
				text: 'First Name',
				type: 'text',
				value: state.firstName,
			},
			{
				id: UserInput.LastName,
				required: true,
				text: 'Last Name',
				type: 'text',
				value: state.lastName,
			},
			{
				id: UserInput.PhoneNumber,
				required: true,
				text: 'Phone Number',
				type: 'text',
				value: state.phoneNumber,
			},
			{
				id: UserInput.EmailAddress,
				required: true,
				text: 'Email',
				type: 'text',
				value: state.emailAddress,
			},
		]);

		if (includeAltFields) {
			fields = fields.concat([
				{
					id: UserInput.AlternativeTime,
					required: false,
					text: 'Preferred Meeting Time',
					type: 'text',
					value: state.alternativeTime,
				},
				{
					id: UserInput.Comments,
					required: false,
					text: 'Comments',
					type: 'textarea',
					value: state.comments,
				},
			]);
		}

		return fields;
	}, [includeAltFields, state, meetingVm.requireCompanyOnMainParticipant]);

	const onMeetingNameInputBlur = () => {
		// @ts-ignore
		meetingVm.updateTitle(meetingName).catch((err: Api.IOperationResultNoValue) => {
			logger.logApiError('SchedulerSetMainContact-Error', err);
			setError(err);
		});
	};

	const onNoteInputBlur = () => {
		if (meetingVm.commentsRequired && !state.comments) {
			dispatch({
				payload: `${meetingVm.commentsLabel ?? 'Notes/Comments'} is required`,
				type: UserError.CommentsError,
			});
		} else {
			meetingVm.updateNotes(state.comments).catch((err: Api.IOperationResultNoValue) => {
				logger.logApiError('SchedulerSetMainContact-Error', err);
				setError(err);
			});
		}
	};

	const onCustomFieldChanged = (updatedState: IState) => {
		meetingVm.setMainParticipant(updatedState).catch((err: Api.IOperationResultNoValue) => {
			logger.logApiError('SchedulerSetMainContact-Error', err);
			setError(err);
		});
	};

	const onMeetingInputChange = (_: UserInput, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setMeetingName(e.target.value);
	};

	const onTextOptInChanged = () => {
		dispatch({ payload: !state.textOptIn, type: UserInput.TextOptIn });
	};
	useEffect(() => {
		const isChecked = Boolean(state.textOptIn);

		if (isChecked && !isValidPhone(state.phoneNumber, true)) {
			dispatch({
				payload: 'must be valid US number',
				type: UserError.PhoneNumberError,
			});
		} else if (isChecked || (!isChecked && state.phoneNumber)) {
			dispatch({
				type: UserError.PhoneNumberError,
			});

			meetingVm.setMainParticipant(state).catch((err: Api.IOperationResultNoValue) => {
				logger.logApiError('SchedulerSetMainContact-Error', err);
				setError(err);
			});
		}
		// Only trigger this when the dropdown is clicked and the textOptIn changes, not state.phoneNumber
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.textOptIn]);

	const onInputBlur = useCallback(
		(field: UserInput) => {
			const { firstName, lastName, phoneNumber, textOptIn, emailAddress, companyName } = state;
			let update = true;
			switch (field) {
				case UserInput.FirstName:
					if (!firstName) {
						dispatch({
							payload: 'first name is required',
							type: UserError.FirstNameError,
						});
						update = false;
					}
					break;
				case UserInput.LastName:
					if (!lastName) {
						dispatch({
							payload: 'last name is required',
							type: UserError.LastNameError,
						});
						update = false;
					}
					break;
				case UserInput.PhoneNumber:
					if (!phoneNumber) {
						dispatch({
							payload: 'phone number is required',
							type: UserError.PhoneNumberError,
						});
						update = false;
					} else if (textOptIn && !isValidPhone(phoneNumber, textOptIn)) {
						dispatch({
							payload: 'must be valid US number',
							type: UserError.PhoneNumberError,
						});
						update = false;
					}
					break;
				case UserInput.EmailAddress:
					if (!emailAddress) {
						dispatch({
							payload: 'email is required',
							type: UserError.EmailAddressError,
						});
						update = false;
					} else if (!isValidEmail(emailAddress)) {
						dispatch({
							payload: 'invalid email format',
							type: UserError.EmailAddressError,
						});
						update = false;
					}
					break;
				case UserInput.CompanyName:
					if (meetingVm.requireCompanyOnMainParticipant && !companyName) {
						dispatch({
							payload: 'company is required',
							type: UserError.CompanyNameError,
						});
						update = false;
					}
					break;
				default:
					break;
			}

			if (update) {
				meetingVm.setMainParticipant(state).catch((err: Api.IOperationResultNoValue) => {
					logger.logApiError('SchedulerSetMainContact-Error', err);
					setError(err);
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state, meetingVm]
	);

	const onInputChange = useCallback(
		(field: UserInput, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			switch (field) {
				case UserInput.FirstName:
					dispatch({ payload: e.target.value, type: UserInput.FirstName });
					return;
				case UserInput.LastName:
					dispatch({ payload: e.target.value, type: UserInput.LastName });
					return;
				case UserInput.PhoneNumber:
					dispatch({ payload: e.target.value, type: UserInput.PhoneNumber });
					return;
				case UserInput.EmailAddress:
					dispatch({ payload: e.target.value, type: UserInput.EmailAddress });
					return;
				case UserInput.AlternativeTime:
					dispatch({
						payload: e.target.value,
						type: UserInput.AlternativeTime,
					});
					return;
				case UserInput.Comments:
					dispatch({ payload: e.target.value, type: UserInput.Comments });
					meetingVm.updateNotes(e.target.value);
					return;
				case UserInput.Location:
					dispatch({ payload: e.target.value, type: UserInput.Location });
					return;
				case UserInput.CompanyName:
					dispatch({ payload: e.target.value, type: UserInput.CompanyName });
					return;
				default:
					return;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state]
	);

	return (
		<>
			{userInfoFields.map((x, i) => (
				<ScheduleMeetingInput
					key={`schedule-meeting-input-${i}`}
					fieldData={x}
					// @ts-ignore
					getAnyErrors={getAnyErrors}
					onInputBlur={onInputBlur}
					onInputChange={onInputChange}
				/>
			))}

			{meetingVm.canEditTitle ? (
				<ScheduleMeetingInput
					key={`name-${userInfoFields.length}`}
					fieldData={{
						id: UserInput.MeetingName,
						required: false,
						text: 'Meeting Name',
						type: 'text',
						// @ts-ignore
						value: meetingName,
					}}
					onInputBlur={onMeetingNameInputBlur}
					onInputChange={onMeetingInputChange}
				/>
			) : null}
			{!includeAltFields && (
				<div className={css(styleSheet.userInfoItem)}>
					<label className={css(pageStyles.inputLabel)} htmlFor='meeting-guests-input'>
						Add Meeting Guests
					</label>
					<ScheduleMeetingGuests inputId='meeting-guests-input' />
				</div>
			)}
			{!includeAltFields && (
				<ScheduleMeetingInput
					key={`notes-${userInfoFields.length}`}
					fieldData={{
						id: UserInput.Comments,
						maxLength: 500,
						required: meetingVm.commentsRequired ?? false,
						text: meetingVm.commentsLabel ?? 'Notes/Comments',
						type: 'textarea',
						value: state.comments,
					}}
					getAnyErrors={getAnyErrors}
					onInputBlur={onNoteInputBlur}
					onInputChange={onInputChange}
				/>
			)}

			{!includeAltFields && meetingVm.contactFormFields
				? meetingVm.contactFormFields.map(field => (
						<CustomFieldLayout
							key={field.fieldName}
							field={field}
							state={state}
							onValueChanged={onCustomFieldChanged}
						/>
					))
				: null}

			{meetingVm.showTextOptIn ? (
				<div className={css(styleSheet.optInCheckboxContainer)}>
					<Checkbox
						className={css(styleSheet.optInCheckbox)}
						id='opt-in-to-text-checkbox'
						onChange={onTextOptInChanged}
						checked={state.textOptIn}
					>
						<p>
							{`I grant ${meetingVm.company} the ability to send relevant text messages to the phone number listed above.`}
						</p>
					</Checkbox>
					<p className={css(styleSheet.optInCheckboxDisclaimer)}>
						Text messaging rates may apply. The frequency of text messages will vary. Reply STOP at any time to
						unsubscribe from text messages.
					</p>
				</div>
			) : null}
		</>
	);
};
