import { SvgIcon } from '../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
}

export const CheckmarkIcon: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon width='24' height='24' className={`checkmark-icon ${className || ''}`}>
			<circle cx='12' cy='12' r='11' fill='#00AAE8' stroke='#E8F3F9' strokeWidth='2' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.9917 17.9126L6.13892 13.061L8.26052 10.9394L10.6089 13.2878L15.5793 6.32904L18.0201 8.07144L10.9917 17.9126Z'
				fill='white'
			/>
		</SvgIcon>
	);
};
