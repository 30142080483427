import { brandPrimary, brandSecondary, mention, mentionDark } from '../../web/styles/colors';
import { BreakPoints, CssSelectors } from '../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	calendarContainer: {
		padding: '0 25px',
		position: 'relative',
		[BreakPoints.forMaxWidth(790)]: {
			padding: 0,
		},
	},
	chooseADate: {
		paddingBottom: 20,
		textAlign: 'center',
	},
	dayPicker: {
		flexShrink: 0,
		margin: 'auto',
		maxWidth: 340,
		...CssSelectors.allDescendants(
			{
				fontWeight: 600,
				padding: '0 10px',
			},
			'.DayPicker .DayPicker-Weekday'
		),
		[BreakPoints.forMaxWidth(350)]: {
			...CssSelectors.allDescendants(
				{
					padding: '0 5px',
				},
				'.DayPicker .DayPicker-Weekday'
			),
			...CssSelectors.allDescendants(
				{
					padding: 5,
				},
				'.DayPicker .DayPicker-Day'
			),
		},
	},
	dayPickerDateNotSelected: {
		background: mention,
		borderRadius: '50%',
	},
	dayPickerDateSelected: {
		background: brandPrimary,
		borderRadius: '50%',
		color: '#fff',
	},
	dayPickerDateToday: {
		':hover': {
			cursor: 'not-allowed',
			...CssSelectors.allChildren({
				display: 'block',
			}),
		},
		border: `1px solid ${brandSecondary}`,
		borderRadius: '50%',
		overflow: 'visible',
		pointerEvents: 'auto',
		position: 'relative',
	},
	tooltip: {
		background: mention,
		border: `1px solid ${mentionDark}`,
		borderRadius: 4,
		bottom: 'calc(100% + 5px)',
		color: '#000',
		display: 'none',
		padding: 10,
		position: 'absolute',
	},
});
