import { background, brandPrimary, grayIconFill, header } from '../../../web/styles/colors';
import { BreakPoints } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	card: {
		backgroundColor: '#fff',
		borderColor: '#E3E7E9',
		borderRadius: 6,
		borderStyle: 'solid',
		borderWidth: 1,
		boxShadow: `0 0 6px 0 rgba(139, 139, 139, 0.10) inset`,
		height: 170,
		margin: 8,
		padding: '26px 33px',
		position: 'relative',
		width: 284,
	},
	container: {
		backgroundColor: background,
		paddingBottom: 60,
	},
	cta: {
		':disabled': {
			opacity: 0.5,
		},
		':hover:disabled': {
			background: brandPrimary,
			cursor: 'default',
			textDecoration: 'none',
		},
		bottom: 26,
		position: 'absolute',
	},
	header: {
		boxShadow: '',
	},
	icon: {
		marginRight: 6,
		marginTop: 4,
	},
	instructions: {
		color: header,
		fontSize: 16,
		paddingRight: 100,
		paddingTop: 16,
		[BreakPoints.forMaxWidth(768)]: {
			paddingRight: 0,
		},
	},
	label: {
		position: 'relative',
	},
	meetingLength: {
		color: grayIconFill,
		fontSize: 14,
	},
	meetingName: {
		color: brandPrimary,
		fontSize: 18,
	},
	meetings: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		margin: 'auto',
		maxWidth: 600,
	},
	meetingsContainer: {
		display: 'block',
	},
	timeText: {
		bottom: 4,
		position: 'absolute',
	},
});
