import { brandSecondary, mention } from '../../web/styles/colors';
import { BreakPoints, truncateTextStyle } from '../../web/styles/styles';
import { MobileBreakpoint } from '../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	calendarIconContainer: {
		background: mention,
		borderRadius: '50%',
		height: 60,
		left: '50%',
		padding: 12,
		position: 'absolute',
		top: 0,
		transform: 'translate(-50%, -30px)',
		width: 60,
		[BreakPoints.forMaxWidth(768)]: {
			display: 'none',
		},
	},
	calendarIconWrapper: {
		background: 'white',
		border: `1px solid ${brandSecondary}`,
		borderRadius: '50%',
		height: 36,
		position: 'relative',
		width: 36,
	},
	companyName: {
		[BreakPoints.forMaxWidth(768)]: {
			display: 'none',
		},
	},
	meetingOwner: {
		...truncateTextStyle,
		fontSize: '24px',
		marginTop: 5,
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			fontSize: '16px',
			marginTop: 0,
		},
	},
});
