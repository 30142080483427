import { brandSecondary } from '../../web/styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	closeButton: {
		position: 'absolute',
		right: 15,
		top: 15,
	},
	container: {
		alignItems: 'center',
		background: '#fff',
		border: '1px solid #BABABA',
		boxShadow: '0 2px 6px 0 rgb(0 0 0 / 20%)',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 400,
		padding: 20,
		width: '96%',
		zIndex: 10,
	},
	icon: {
		margin: '30px auto 10px auto',
	},
	message: {
		marginBottom: 30,
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		marginBottom: 15,
		textAlign: 'center',
	},
});
