import { css } from 'aphrodite';
import moment from 'moment';
import { useCallback } from 'react';
import * as React from 'react';
import { Modifier, Modifiers } from 'react-day-picker/types/common';
import { DayPicker } from '../../web/components/DayPicker';
import { LoadingSpinner } from '../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../web/styles/styles';
import { styleSheet as pageStyles } from '../styles/styles';
import { styleSheet } from './styles';

interface IEditSendOptionsDayPickerModifiers {
	disabled?: boolean;
	hasCampaign?: boolean;
	hasCompletedCampaign?: boolean;
	hasQueuedCampaign?: boolean;
	selected?: boolean;
}
interface IProps {
	allowSameDay?: boolean;
	breakpoint: 'mobile' | 'desktop';
	enabledDays?: boolean[];
	hide?: boolean;
	onDayChange: (date: moment.Moment) => void;
	onWillChangeToNextMonth: () => void;
	onWillChangeToPreviousMonth: () => void;
	processing?: boolean;
	selectedDate?: moment.Moment;
	selectedMonth?: moment.Moment;
	selectedTimezone?: string;
}

export const ScheduleMeetingDayPicker: React.FC<IProps> = ({
	allowSameDay,
	breakpoint = 'mobile',
	enabledDays,
	hide,
	onDayChange,
	onWillChangeToNextMonth,
	onWillChangeToPreviousMonth,
	processing,
	selectedDate,
	selectedMonth,
}) => {
	const getDisabledDays = useCallback(() => {
		const allDisabledDays: Modifier[] = [
			// disable all days before today/tomorrow
			{
				before: allowSameDay ? new Date() : new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1),
			},
		];

		// disable any dates the host has disabled in the config or on their calendar
		if (!enabledDays?.length) {
			allDisabledDays.push({ after: new Date() });
		} else {
			enabledDays?.forEach((d, i) => {
				if (!d) {
					// @ts-ignore
					// @ts-ignore
					allDisabledDays.push(new Date(`${selectedMonth.format('MMM')} ${i + 1}, ${selectedMonth.year()}`));
				}
			});
		}

		return allDisabledDays;
	}, [allowSameDay, enabledDays, selectedMonth]);

	const setDate = useCallback(
		(day: Date) => {
			const date = day ? moment(day).utc() : moment().utc();
			onDayChange(date);
		},
		[onDayChange]
	);

	const noop = (e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const onRenderDay = useCallback(
		(
			day: Date,
			modifiers: Partial<Modifiers> & IEditSendOptionsDayPickerModifiers,
			props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
		) => {
			if (modifiers?.selected) {
				return (
					<span className={`${props?.className || ''} ${css(styleSheet.dayPickerDateSelected)}`}>{day.getDate()}</span>
				);
			} else if (!modifiers?.disabled && !modifiers?.outside) {
				return (
					<span
						className={`${props?.className || ''} ${css(
							styleSheet.dayPickerDateNotSelected,
							modifiers?.today && styleSheet.dayPickerDateToday
						)}`}
					>
						{day.getDate()}
					</span>
				);
			} else if (modifiers?.today && !allowSameDay) {
				return (
					<span
						className={`${props?.className || ''} ${css(styleSheet.dayPickerDateToday)}`}
						onClick={noop}
						onMouseDown={noop}
						onTouchStart={noop}
						onTouchEnd={noop}
					>
						{day.getDate()}
						<div className={css(styleSheet.tooltip)}>Same day appointments are unavailable.</div>
					</span>
				);
			}

			return null;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const canChangeToPreviousMonth = () => {
		// @ts-ignore
		return selectedMonth > moment();
	};

	return (
		<div className={css(styleSheet.calendarContainer, hide && pageStyles.hide)}>
			{breakpoint === 'mobile' && <div className={css(styleSheet.chooseADate)}>Choose a date:</div>}
			{processing && <LoadingSpinner type='small' className={css(baseStyleSheet.absoluteCenter)} />}
			<DayPicker
				allowPastDates={false}
				canChangeToPreviousMonth={canChangeToPreviousMonth()}
				className={css(styleSheet.dayPicker)}
				disabledDays={getDisabledDays()}
				onDayClick={setDate}
				onRenderDay={onRenderDay}
				onWillChangeToNextMonth={onWillChangeToNextMonth}
				onWillChangeToPreviousMonth={onWillChangeToPreviousMonth}
				// @ts-ignore
				selectedDays={processing ? null : selectedDate?.toDate()}
				showOverflowDates={false}
				month={selectedMonth?.toDate()}
			/>
		</div>
	);
};
