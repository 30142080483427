import { OopsNotFoundGraphic } from '../../web/components/svgs/graphics/OopsNotFoundGraphic';
import { styleSheet as pageStyles } from '../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	text?: string;
}

export const ScheduleMeetingNotFound: React.FC<IProps> = props => {
	const { text } = props;
	return (
		<div className={css(pageStyles.background)}>
			<div className={css(pageStyles.container, styleSheet.oopsNotFoundContainer)}>
				<OopsNotFoundGraphic />
				<div className={css(styleSheet.oopsNotFound)}>{text || 'sorry...this is an invalid meeting url.'}</div>
			</div>
		</div>
	);
};
