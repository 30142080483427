import { urgentDate } from '../../web/styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		paddingBottom: 15,
	},
	inputError: {
		color: urgentDate,
		fontStyle: 'italic',
		paddingLeft: 15,
	},
	textArea: {
		':focus': {
			border: '1px solid #ccc',
			outline: 'none',
		},
		':focus-visible': {
			border: '1px solid #ccc',
			outline: 'none',
		},
		background: '#F9F9F9',
		border: '1px solid #ccc',
		borderRadius: 3,
		height: '5em',
		padding: 10,
		resize: 'none',
		width: '100%',
	},
});
