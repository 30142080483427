import {
	brandPrimary,
	brandPrimaryHover,
	brandSecondary,
	grayIconFill,
	mention,
	navigation,
	titles,
} from '../../web/styles/colors';
import { BreakPoints, CssSelectors, truncateTextStyle } from '../../web/styles/styles';
import {
	colorPriority0 as colorPriority1,
	colorPriority1 as colorPriority2,
	colorPriority2 as colorPriority3,
	colorPriority3 as colorPriority4,
	colorPriority4 as colorPriority5,
	colorPriority5 as colorPriority6,
} from '../styles/colors';
import { MobileBreakpoint } from '../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	availabilities: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		maxHeight: 225,
		overflow: 'auto',
		[BreakPoints.forMaxWidth(1020)]: {
			paddingRight: 10,
		},
		[BreakPoints.forMaxWidth(810)]: {
			justifyContent: 'center',
		},
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			maxHeight: 'unset',
		},
	},
	availabilitiesContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	availabilitiesHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
				maxWidth: 200,
				minWidth: 200,
			},
			'.schedule-meeting-timezone-picker'
		),
		[BreakPoints.forMaxWidth(850)]: {
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
	availability: {
		':hover': {
			cursor: 'pointer',
		},
		background: mention,
		border: '3px solid #fff',
		borderRadius: '30px',
		maxHeight: 42,
		outline: 'none',
		padding: '9px 14px',
		textAlign: 'center',
		width: 100,
	},
	availabilityCheck: {
		height: 24,
		position: 'absolute',
		right: -2,
		top: -4,
		width: 24,
	},
	availabilityContainer: {
		border: '3px solid #fff',
		borderRadius: '30px',
		position: 'relative',
	},
	availabilityContainerSelected: {
		border: `3px solid ${brandSecondary}`,
	},
	colorPriority1: {
		backgroundColor: colorPriority1,
	},
	colorPriority2: {
		backgroundColor: colorPriority2,
	},
	colorPriority3: {
		backgroundColor: colorPriority3,
	},
	colorPriority4: {
		backgroundColor: colorPriority4,
	},
	colorPriority5: {
		backgroundColor: colorPriority5,
	},
	colorPriority6: {
		backgroundColor: colorPriority6,
	},
	colorPriorityExample: {
		borderRadius: 5,
		height: 10,
		marginLeft: 4,
		marginRight: 4,
		width: 10,
	},
	colorPriorityRow: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 8,
		paddingLeft: 16,
		paddingRight: 16,
		width: '100%',
	},
	colorPriorityText: {
		color: grayIconFill,
		fontSize: 12,
	},
	container: {},
	link: {
		':hover': {
			color: brandPrimaryHover,
		},
		color: brandPrimary,
	},
	noAvailabilities: {
		color: navigation,
		fontSize: '14px',
		height: 192,
		minWidth: '100%',
		paddingTop: 40,
		textAlign: 'center',
	},
	selectedAvailability: {
		background: brandSecondary,
		color: '#fff',
	},
	selectedDateString: {
		'::before': {
			borderLeft: `2px solid ${brandSecondary}`,
			borderTop: `2px solid ${brandSecondary}`,
			content: "' '",
			display: 'none',
			height: 12,
			left: 12,
			position: 'absolute',
			top: '25%',
			transform: 'rotate3d(0, 0, 1, -45deg) translate3d(0, -50%, 0)',
			width: 12,
			[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
				display: 'block',
			},
		},
		overflow: 'visible',
		paddingTop: 12,
		position: 'relative',
		[BreakPoints.forMaxWidth(850)]: {
			color: titles,
			fontSize: 16,
			fontWeight: 'bold',
			minWidth: '100%',
			padding: '0 0 18px',
			textAlign: 'center',
		},
	},
	timezoneNoEdit: {
		position: 'relative',
		...CssSelectors.allChildren(
			{
				bottom: '70%',
				color: brandPrimaryHover,
				display: 'none',
				fontSize: '12px',
				position: 'absolute',
				right: 0,
				[BreakPoints.forMaxWidth(850)]: {
					display: 'block',
					fontSize: 14,
					marginLeft: 10,
					position: 'relative',
				},
			},
			'.change-timezone-button'
		),
		// Date and time selector showing
		[BreakPoints.forMinWidth(850)]: {
			':hover': {
				cursor: 'pointer',
				...CssSelectors.allChildren(
					{
						display: 'block',
					},
					'.change-timezone-button'
				),
			},
			overflow: 'visible',
			paddingTop: 12,
		},
		// Two step selection
		[BreakPoints.forMaxWidth(850)]: {
			color: titles,
			display: 'flex',
			fontSize: 16,
			fontWeight: 'bold',
			minWidth: '100%',
			padding: '8px 0',
			textAlign: 'center',
		},
	},
	timezoneOption: {
		...truncateTextStyle,
	},
	timezonePickerContainer: {
		display: 'flex',
	},
	timezones: {
		maxWidth: 200,
	},
});
