import { ClockIcon } from '../../web/components/svgs/icons/ClockIcon';
import { CompactLogoIcon2023, ECompactLogoIcon2023Type } from '../../web/components/svgs/icons/CompactLogoIcon2023';
import { grayIconFill } from '../../web/styles/colors';
import { getDurationText } from '../UiUtils';
import { useMeeting } from '../contexts/meeting';
import { BookingTigerIcon } from '../icons/BookingTigerIcon';
import { styleSheet as pageStyles } from '../styles/styles';
import { styleSheet } from './styles';
import { css } from 'aphrodite';

export const ScheduleMeetingHeaderGroup = () => {
	const { meetingVm } = useMeeting();

	return (
		<header className={css(pageStyles.header, styleSheet.container)}>
			<figure className={css(styleSheet.iconContainer)}>
				{meetingVm?.accountType === 'Coffee' ? (
					<BookingTigerIcon />
				) : (
					<CompactLogoIcon2023 type={ECompactLogoIcon2023Type.White} className={css(styleSheet.iconLevitateAccess)} />
				)}
			</figure>
			<div className={css(styleSheet.subText)}>Schedule a Meeting</div>
			<div className={css(styleSheet.titleText)}>{meetingVm?.title}</div>
			<div className={css(pageStyles.label, styleSheet.clockContainer)}>
				<ClockIcon className={css(styleSheet.clockIcon)} fillColor={grayIconFill} />
				{meetingVm.duration ? getDurationText(meetingVm.duration) : ''}
			</div>
		</header>
	);
};
