import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useEventLogging } from '../../../models/Logging';
import { debounce, isValidEmail } from '../../../models/UiUtils';
import { Button } from '../../../web/components/Button';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { ITimezoneData } from '../../../web/components/TimezonePicker';
import { BookingGraphic } from '../../../web/components/svgs/graphics/BookingGraphic';
import '../../../web/styles/inputs.less';
import { baseStyleSheet } from '../../../web/styles/styles';
import { ScheduleMeetingAvailabilityPicker } from '../../ScheduleMeetingAvailabilityPicker';
import { ScheduleMeetingDayPicker } from '../../ScheduleMeetingDayPicker';
import { ScheduleMeetingHeader } from '../../ScheduleMeetingHeader';
import { ScheduleMeetingHeaderGroup } from '../../ScheduleMeetingHeaderGroup';
import { ScheduleMeetingLocation } from '../../ScheduleMeetingLocation/Index';
import { ScheduleMeetingNotFound } from '../../ScheduleMeetingNotFound';
import { ScheduleMeetingUserInfo } from '../../ScheduleMeetingUserInfo';
import { Breakpoint, calculateTeamTimeSlots, getMonthFromOffset, getSelectedDateAsString } from '../../UiUtils';
import { useErrorMessage } from '../../contexts/errorMessage';
import { useMeeting } from '../../contexts/meeting';
import { MobileBreakpoint, styleSheet as pageStyles } from '../../styles/styles';
import { IAvailabilitySlot, ITeamMemberAvailabilities } from '../../types';
import { styleSheet } from './styles';

const getBreakpoint = () => (window.innerWidth <= MobileBreakpoint ? 'mobile' : 'desktop');

const TRADESHOW_URL_KEY = 'tradeshowUrl';
const getRedirectUrl = () => {
	return sessionStorage.getItem(TRADESHOW_URL_KEY) ?? '';
};
const setRedirectUrl = () => {
	sessionStorage.setItem(TRADESHOW_URL_KEY, window.location.href);
};

export const ScheduleMeetingApp = () => {
	const location = useLocation();
	// Tradeshow mode allows the user to go back to the same calendar instead of closing the tab
	//   To enable simply put a query string param that includes 'tradeshow'
	//   ex #/123/30-minute-meeting?tradeshow
	const tradeshowMode = Boolean(location.search?.includes('tradeshow'));

	const { meetingVm } = useMeeting();
	const { setError } = useErrorMessage();
	const h = useHistory();
	const logger = useEventLogging();
	const [selectedTimezone, setSelectedTimezone] = useState<string>(moment.tz.guess());
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
	const [selectedAvailability, setSelectedAvailability] = useState<IAvailabilitySlot>(undefined);
	const [userInfo, setUserInfo] = useState<Api.ISchedulerContact>(undefined);
	const [inviteeSelectedMeetingLocation, setInviteeSelectedMeetingLocation] = useState<Api.MeetingLocation>(null);
	const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint());
	const [currentStep, setCurrentStep] = useState(1);
	const [scheduling, setScheduling] = useState(false);
	const [fetchingMeetingInfo, setFetchingMeetingInfo] = useState(false);
	const [fetchingAvailabilities, setFetchingAvailabilities] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [currentOffset, setOffset] = useState(0);
	const [teamAvailabilities, setTeamAvailabilities] = useState<ITeamMemberAvailabilities[]>([]);
	const [fatalError, setFatalError] = useState(false);

	const IS_FETCHING = fetchingMeetingInfo || fetchingAvailabilities;

	const updateBreakpoint = () => {
		debounce(() => {
			const updatedBreakpoint = getBreakpoint();
			if (breakpoint !== updatedBreakpoint) {
				setBreakpoint(updatedBreakpoint);
			}
		}, 50)();
	};

	useEffect(() => {
		setFetchingMeetingInfo(true);

		meetingVm
			.init(selectedTimezone)
			.then(() => {
				if (meetingVm?.shortCode?.includes('tm-') && meetingVm?.meetingData?.id) {
					if (tradeshowMode) {
						setRedirectUrl();
					}
					h.push({
						pathname: `/team/${meetingVm?.meetingData?.id}`,
						search: tradeshowMode ? '?tradeshow' : undefined,
					});
					return;
				}
				onGetAvailabilities(currentOffset, true);
				if (!!meetingVm.contacts?.length && !!meetingVm.contacts[0]?.timeZoneId) {
					setSelectedTimezone(meetingVm.contacts[0].timeZoneId);
				}

				const minStartMomemt = meetingVm.minStartDate ? moment(meetingVm.minStartDate) : null;
				if (minStartMomemt && minStartMomemt.isAfter(moment().endOf('day'))) {
					setSelectedDate(minStartMomemt);
				}
			})
			.catch((err: Api.IOperationResultNoValue) => {
				logger.logApiError('SchedulerInit-Error', err);
				setFatalError(true);
			})
			.finally(() => {
				setFetchingMeetingInfo(false);
				setMounted(true);
			});

		return () => {
			meetingVm.reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		window.addEventListener('resize', updateBreakpoint);
		return () => window.removeEventListener('resize', updateBreakpoint);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [breakpoint]);

	const isInviteeLocationSelectionRequired = meetingVm.locationConfig?._type === Api.MeetingType.AllowInvitee;
	const meetingLocationValid = isInviteeLocationSelectionRequired ? inviteeSelectedMeetingLocation : true;

	const allInfoEntered =
		userInfo &&
		userInfo?.firstName &&
		userInfo?.lastName &&
		userInfo?.phoneNumber &&
		userInfo?.emailAddress &&
		isValidEmail(userInfo?.emailAddress) &&
		meetingVm?.mainParticipant?.firstName &&
		meetingVm?.mainParticipant?.lastName &&
		meetingVm?.mainParticipant?.phoneNumber &&
		meetingVm?.mainParticipant?.emailAddress &&
		meetingLocationValid &&
		(!meetingVm.commentsRequired || meetingVm.meetingData.comments);

	const handleClose = () => {
		if (window.opener) {
			opener.location.reload();
		}
		window.close();
	};

	const getEnabledDays = useCallback(() => {
		if (meetingVm.teamMeetingId) {
			if (teamAvailabilities?.length) {
				const days = teamAvailabilities[0]?.availabilities?.length;
				const enabledDays = new Array(days).fill(false);
				for (const teamMemberAvailability of teamAvailabilities) {
					if (teamMemberAvailability?.availabilities?.length === days) {
						teamMemberAvailability?.availabilities?.forEach((availability, i) => {
							if (availability?.length) {
								enabledDays[i] = true;
							}
						});
					}
				}
				return enabledDays;
			}
			return [];
		}
		return meetingVm.availabilities?.map(x => !!x?.length);
	}, [meetingVm.teamMeetingId, meetingVm.availabilities, teamAvailabilities]);

	const getAvailabilities = useCallback(() => {
		if (!meetingVm.availabilities?.length || !selectedDate) {
			return [];
		}

		return (meetingVm.availabilities[selectedDate.date() - 1] || [])?.map(a => {
			return { ids: [], priority: 0, time: moment(a.start) };
		});
	}, [meetingVm.availabilities, selectedDate]);

	const getTeamTimeSlots = useCallback(() => {
		return calculateTeamTimeSlots(teamAvailabilities, selectedDate);
	}, [teamAvailabilities, selectedDate]);

	const goToStep = (step: number) => () => {
		if (step === 1) {
			onGetAvailabilities(currentOffset);
		}

		setCurrentStep(step);
	};

	const onAvailabilityChange = (availability: IAvailabilitySlot) => {
		setSelectedAvailability(availability);
		if (selectedDate && breakpoint === 'mobile') {
			meetingVm.setDateTime(availability.time.toDate(), availability.ids).catch((err: Api.IOperationResultNoValue) => {
				logger.logApiError('SchedulerAvailabilityChange-Error', err);
				setError(err);
			});
			setCurrentStep(3);
		}
	};

	const onAltSubmit = useCallback(() => {
		if (allInfoEntered) {
			setScheduling(true);
			meetingVm
				.altRequestMeeting(userInfo)
				.then(() => {
					setCurrentStep(5);
					setScheduling(false);
				})
				.catch((err: Api.IOperationResultNoValue) => {
					logger.logApiError('SchedulerScheduleMeeting-Error', err);
					setError(err);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo, meetingVm]);

	const onDayChange = (date: moment.Moment) => {
		if (date) {
			setSelectedAvailability(null);
			setSelectedDate(date);
			setCurrentStep(2);
		}
	};

	const onMonthChange = (offset: number) => {
		setSelectedAvailability(null);
		setSelectedDate(null);
		onGetAvailabilities(currentOffset + offset);
	};

	const onWillChangeToNextMonth = () => onMonthChange(1);

	const onWillChangeToPreviousMonth = () => onMonthChange(-1);

	const onError = () => {
		setCurrentStep(99);
	};

	const teamAvailabilitiesRef = useRef(teamAvailabilities);
	teamAvailabilitiesRef.current = teamAvailabilities;

	const onGetAvailabilities = async (offset: number, initialLoad?: boolean) => {
		setFetchingAvailabilities(true);
		if (offset !== currentOffset) {
			setOffset(offset);
		}
		try {
			if (meetingVm.teamMeetingId) {
				setTeamAvailabilities([]);
				let newTeamAvailabilities: ITeamMemberAvailabilities[] = [];
				if (meetingVm.meetingConfigs?.length) {
					newTeamAvailabilities = (
						await Promise.allSettled(
							meetingVm.meetingConfigs.map(config => meetingVm.getTeamAvailabilities(offset, config))
						)
					)
						.map(x => (x.status === 'fulfilled' ? x.value : null))
						.filter(Boolean);

					if (newTeamAvailabilities.length === 0) {
						throw new Error('No valid meeting configs to load');
					}

					setTeamAvailabilities(newTeamAvailabilities);
				} else {
					throw new Error('No meeting configs to load from');
				}
			} else {
				await meetingVm.getAvailabilities(offset);
				if (initialLoad) {
					// no availabilities were found for the currently selected month. Load next month
					if (meetingVm.availabilities.filter(x => !!x).length > 0) {
						let month: moment.Moment;
						for (const a of meetingVm.availabilities) {
							if (a) {
								month = moment(a[0].start);
								break;
							}
						}
						setSelectedDate(month);
					} else {
						if (offset <= 2) {
							onGetAvailabilities(offset + 1, true);
						}
					}
				}
			}
			setFetchingAvailabilities(false);
		} catch (err) {
			logger.logApiError('SchedulerGetAvailabilities-Error', err);
			onError();
			setFetchingAvailabilities(false);
		}
	};

	const onNextClick = useCallback(() => {
		if (selectedDate && selectedAvailability) {
			meetingVm
				.setDateTime(selectedAvailability.time.toDate(), selectedAvailability.ids)
				.catch((err: Api.IOperationResultNoValue) => {
					logger.logApiError('SchedulerAvailabilityChange-Error', err);
					setError(err);
				});
			setCurrentStep(3);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, selectedAvailability, currentStep]);

	const onScheduleMeetingClick = useCallback(() => {
		if (allInfoEntered) {
			setScheduling(true);
			meetingVm
				.scheduleMeeting()
				.then(() => {
					setCurrentStep(4);
					setScheduling(false);
				})
				.catch((err: Api.IOperationResultNoValue) => {
					logger.logApiError('SchedulerScheduleMeeting-Error', err);
					setError(err);
					setScheduling(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, selectedAvailability, selectedTimezone, userInfo, meetingVm]);

	const onTimezoneChange = useCallback((timezone: ITimezoneData) => {
		setSelectedTimezone(timezone.name);
		meetingVm
			.setMainParticipant({
				...meetingVm.mainParticipant,
				timeZoneId: timezone.name,
			})
			.catch(err => {
				logger.logApiError('SchedulerScheduleMeeting-Error', err);
				setError(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSelectedMonth = useCallback(() => {
		return getMonthFromOffset(currentOffset);
	}, [currentOffset]);

	const reloadFromRedirect = () => {
		const redirectUrl = getRedirectUrl();

		if (tradeshowMode && redirectUrl) {
			setSelectedDate(moment());
			setSelectedAvailability(null);
			setCurrentStep(1);
			window.location.replace(redirectUrl);
		}
	};

	const renderContent = () => {
		if (!mounted) {
			return null;
		}

		const availableAvailabilities = meetingVm.teamMeetingId ? getTeamTimeSlots() : getAvailabilities();

		const showPriority =
			!!meetingVm?.teamMeetingId &&
			!meetingVm?.meetingData?.hidePriorities &&
			!!selectedDate &&
			availableAvailabilities.length > 0;

		const scheduledOutcome = meetingVm.isReschedule ? 'rescheduled' : 'scheduled';

		return (
			<>
				<div
					className={css(
						styleSheet.main,
						(currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 99) && styleSheet.flexCenter
					)}
				>
					{(currentStep === 1 || currentStep === 2) && (
						<>
							<ScheduleMeetingDayPicker
								allowSameDay={meetingVm.allowSameDay}
								breakpoint={breakpoint}
								enabledDays={getEnabledDays()}
								hide={!showCalendar()}
								onDayChange={onDayChange}
								onWillChangeToNextMonth={onWillChangeToNextMonth}
								onWillChangeToPreviousMonth={onWillChangeToPreviousMonth}
								processing={fetchingMeetingInfo}
								selectedDate={selectedDate}
								selectedMonth={getSelectedMonth()}
							/>
							<ScheduleMeetingAvailabilityPicker
								availabilities={availableAvailabilities}
								breakpoint={breakpoint}
								goToStep={goToStep}
								hide={!showAvailabilities()}
								onAvailabilityChange={onAvailabilityChange}
								onTimezoneChange={onTimezoneChange}
								processing={fetchingMeetingInfo}
								selectedAvailability={selectedAvailability}
								selectedDate={selectedDate}
								selectedTimezone={selectedTimezone}
								showPriority={showPriority}
							/>
						</>
					)}
					{(currentStep === 3 || currentStep === 99) && (
						<div className={css(styleSheet.userInfoContainer)}>
							<ScheduleMeetingUserInfo includeAltFields={currentStep === 99} onInfoChange={onUserInfoChange} />
							{meetingVm.locationConfig._type === Api.MeetingType.AllowInvitee && (
								<ScheduleMeetingLocation
									inviteeSelectedMeetingLocation={inviteeSelectedMeetingLocation}
									setInviteeSelectedMeetingLocation={setInviteeSelectedMeetingLocation}
								/>
							)}
							{scheduling && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />}
						</div>
					)}
					{(currentStep === 4 || currentStep === 5) && (
						<div className={css(styleSheet.confirmationContainer)}>
							<BookingGraphic />
							{currentStep === 4 ? (
								<>
									<h2>Thanks! The meeting has been {scheduledOutcome}.</h2>
									<div className={css(styleSheet.text)}>{meetingVm.host}</div>
									<div className={css(pageStyles.pill, styleSheet.marginBottom20)}>
										<time dateTime={`${selectedAvailability.time}`}>
											{selectedDate
												? `${getSelectedDateAsString(
														selectedAvailability.time,
														breakpoint
													)} at ${selectedAvailability.time.format('h:mm A')}`
												: ''}
										</time>
									</div>

									{meetingVm.teamMeetingId ? (
										<p className={css(styleSheet.text)}>A calendar invite has been sent.</p>
									) : (
										<>
											<p className={css(styleSheet.text)}>{`We will send you ${
												(meetingVm.guestParticipants || []).length > 0 ? 'and your guests' : ''
											} a calendar invite shortly.`}</p>
											<a
												className={css(baseStyleSheet.brandLink, styleSheet.downloadLink)}
												href={`${meetingVm.baseUrl}/scheduler/${meetingVm.id}/ical`}
												target='_blank'
												rel='noreferrer'
											>
												Download iCal
											</a>
										</>
									)}
								</>
							) : (
								<div>{`Thanks! We've notified ${
									meetingVm.host ? meetingVm.host.split(' ')[0] : 'the host of this meeting.'
								}.`}</div>
							)}
						</div>
					)}
				</div>
				<footer className={css(styleSheet.footer, currentStep === 3 && styleSheet.step3Footer)}>
					{(currentStep === 1 || currentStep === 2) && (
						<Button
							className={`${css(styleSheet.cta)} nextCta`}
							disabled={!selectedDate || !selectedAvailability}
							label='Next'
							onClick={onNextClick}
						/>
					)}
					{currentStep === 3 && (
						<>
							<div className={css(styleSheet.footerSide)}>
								<div className={css(styleSheet.dateAndTime)}>
									{getSelectedDateAsString(selectedDate, breakpoint)} at {selectedAvailability.time.format('h:mm A')}
								</div>
								<Button className={css(baseStyleSheet.brandLink)} kind='custom' label='Change' onClick={goToStep(1)} />
							</div>
							<Button
								className={css(styleSheet.cta)}
								disabled={!allInfoEntered}
								label={meetingVm.isReschedule ? 'Reschedule Meeting' : 'Schedule Meeting'}
								onClick={onScheduleMeetingClick}
							/>
							<div className={css(styleSheet.footerSide)} />
						</>
					)}

					{currentStep === 4 ? (
						<div>
							{tradeshowMode ? (
								<Button kind='primary' label='Return to Booking Page' onClick={reloadFromRedirect} />
							) : (
								<Button kind='primary' label='Close' onClick={handleClose} />
							)}
						</div>
					) : null}

					{currentStep === 99 && <Button label='Submit' onClick={onAltSubmit} />}
				</footer>
			</>
		);
	};

	const onUserInfoChange = useCallback(
		(info: Api.ISchedulerContact) => {
			setUserInfo(info);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userInfo]
	);

	const showAvailabilities = useCallback(() => {
		return currentStep === 2 || (breakpoint === 'desktop' && currentStep === 1);
	}, [currentStep, breakpoint]);

	const showCalendar = useCallback(() => {
		return currentStep === 1 || (breakpoint === 'desktop' && currentStep === 2);
	}, [currentStep, breakpoint]);

	if (fatalError || (!meetingVm.shortCode && !meetingVm.teamMeetingId)) {
		return <ScheduleMeetingNotFound />;
	}

	return (
		<div className={css(pageStyles.background)}>
			<div className={css(pageStyles.container)}>
				{meetingVm.teamMeetingId ? (
					<ScheduleMeetingHeaderGroup />
				) : (
					<ScheduleMeetingHeader fetching={fetchingMeetingInfo} />
				)}
				<div className={css(styleSheet.body)}>{IS_FETCHING ? <LoadingSpinner /> : renderContent()}</div>
			</div>
		</div>
	);
};
