import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { useEventLogging } from '../../models/Logging';
import { isValidEmail } from '../../models/UiUtils';
import { Button } from '../../web/components/Button';
import { TextInput } from '../../web/components/TextInput';
import { DeprecatedXIcon } from '../../web/components/svgs/icons/DeprecatedXIcon';
import { navigation } from '../../web/styles/colors';
import { useErrorMessage } from '../contexts/errorMessage';
import { useMeeting } from '../contexts/meeting';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	inputId?: string;
}

export const ScheduleMeetingGuests: React.FC<IProps> = ({ className = '', inputId = 'guest-email' }) => {
	const { meetingVm } = useMeeting();
	const { setError } = useErrorMessage();
	const logger = useEventLogging();
	const [error, setUserError] = useState('');
	const [newGuest, setNewGuest] = useState<string>('');

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserError('');
		setNewGuest(e.target.value);
	};

	const onAddClick = () => {
		if (!newGuest) {
			setUserError('you must enter a valid email');
		} else if (!isValidEmail(newGuest)) {
			setUserError('invalid email');
		} else if (meetingVm.guestParticipants?.find(g => g.emailAddress === newGuest)) {
			setUserError('this email has already been added');
		} else {
			meetingVm
				.createNewGuestParticipant(newGuest)
				.then(() => {
					reset();
				})
				.catch((err: Api.IOperationResultNoValue) => {
					logger.logApiError('SchedulerCreateGuest-Error', err);
					setError(err);
				});
		}
	};

	const onRemoveGuestClick = (guest: Api.ISchedulerContact) => () => {
		setUserError('');
		meetingVm.removeGuestParticipant(guest).catch((err: Api.IOperationResultNoValue) => {
			logger.logApiError('SchedulerRemoveGuest-Error', err);
			setError(err);
		});
	};

	const reset = () => {
		setUserError('');
		setNewGuest('');
	};

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<div className={css(styleSheet.inputContainer)}>
				<TextInput
					className={css(styleSheet.addMeetingGuestInput)}
					inputId={inputId}
					onChange={onInputChange}
					placeholder='Guest email address'
					type='text'
					value={newGuest}
				/>
				<Button disabled={!newGuest} label='Add' onClick={onAddClick} />
			</div>
			{error && <p className={css(styleSheet.error)}>{error}</p>}
			<div className={css(styleSheet.meetingGuestsContainer)}>
				{(meetingVm.guestParticipants || []).map((g, i) => (
					<div key={i} className={css(styleSheet.text, styleSheet.meetingGuest)}>
						<Button
							accessory={<DeprecatedXIcon fillColor={navigation} />}
							kind='icon'
							label=''
							onClick={onRemoveGuestClick(g)}
						/>
						{g.emailAddress}
					</div>
				))}
			</div>
		</div>
	);
};
