import * as React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { EventLogger } from '../../extViewmodels';
import { AppHashHistory } from '../../models';
import { BugsnagEventLogger } from '../../models/Logging';
import { ScheduleMeetingNotFound } from '../ScheduleMeetingNotFound';
import { ScheduleMeetingApp } from '../containers/ScheduleMeetingApp';
import { ScheduleMeetingLanding } from '../containers/ScheduleMeetingLanding';
import { LandingProvider } from '../contexts/landing';
import { MeetingProvider } from '../contexts/meeting';

export const ScheduleMeetingRouter: React.FC = () => {
	const envName = process.env.BUGSNAG_ENV as BUGSNAG_ENV;
	const bugsnagEventLogger = new BugsnagEventLogger(envName);
	const ErrorBoundary = bugsnagEventLogger.getErrorBoundary();
	EventLogger.addLogger(bugsnagEventLogger);

	const onRenderLanding = () => {
		return (
			<LandingProvider>
				<ScheduleMeetingLanding />
			</LandingProvider>
		);
	};

	const onRenderMeeting = () => {
		return (
			<MeetingProvider>
				<ScheduleMeetingApp />
			</MeetingProvider>
		);
	};

	const onRenderNotFound = () => {
		return <ScheduleMeetingNotFound />;
	};

	return (
		<ErrorBoundary>
			<Router history={AppHashHistory}>
				<Switch>
					<Route path='/landing/:shortCode' render={onRenderLanding} />
					<Route path='/team/:id' render={onRenderMeeting} />
					<Route path='/:shortCode/:vanityPath' render={onRenderMeeting} />
					<Route path='*' render={onRenderNotFound} />
				</Switch>
			</Router>
		</ErrorBoundary>
	);
};
