import { css } from 'aphrodite';
import { CalendarDateIcon } from '../../web/components/svgs/icons/CalendarDateIcon';
import { brandSecondary } from '../../web/styles/colors';
import { baseStyleSheet } from '../../web/styles/styles';
import { styleSheet as pageStyles } from '../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	company: string;
	host: string;
	isReschedule: boolean;
}

export const ScheduleMeetingHeaderHost = ({ company, host, isReschedule }: IProps) => {
	const meetingType = isReschedule ? 'Reschedule' : 'Schedule';
	return (
		<div>
			<div className={css(styleSheet.calendarIconContainer)}>
				<figure className={css(styleSheet.calendarIconWrapper)}>
					<CalendarDateIcon className={css(baseStyleSheet.absoluteCenter)} fillColor={brandSecondary} />
				</figure>
			</div>
			<p className={css(pageStyles.label)}>{meetingType} a Meeting with</p>
			<h1 className={css(styleSheet.meetingOwner)}>{host}</h1>
			<p className={css(pageStyles.text, styleSheet.companyName)}>{company}</p>
		</div>
	);
};
