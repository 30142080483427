import * as Api from '@ViewModels';

interface IParams {
	shortCode?: string;
}

export class WebServiceHelper {
	public params: IParams = {};

	constructor() {
		this.getQueryParams();
	}

	get baseUrl() {
		return process.env.API_URL || 'https://api.levitate.ai';
	}

	private buildFetchRequest = (path: string, method: Api.HTTPMethod, obj: any) => {
		// Check for a special method called FILEPOST, which we will transform to POST but will not convert body to JSON
		const isFormDataBody = obj instanceof FormData;

		// Define headers
		const headers = new Headers();
		if (isFormDataBody) {
			// DO NOT INCLUDE A HEADER FOR FILE POSTS
		} else {
			headers.append('Content-Type', 'application/json; charset=utf-8');
		}
		headers.append('Accept', 'application/json');
		headers.append('Pragma', 'no-cache');
		headers.append('Cache-Control', 'no-cache');

		const url = `${this.baseUrl}/${path}`;
		const config: RequestInit = {
			headers,
			method,
			mode: 'cors',
			redirect: 'follow',
		};

		if (method.toLowerCase() !== 'get' && !!obj) {
			config.body = isFormDataBody ? obj : JSON.stringify(obj);
		}

		const request = new Request(url, config);
		return request;
	};

	public async fetch<T>(path: string, method: Api.HTTPMethod, obj?: any): Promise<Api.IOperationResult<T>> {
		const request = this.buildFetchRequest(path, method, obj);

		try {
			const response = await fetch(request);

			const apiResponse: Api.IOperationResult<T> = await response.json();
			return apiResponse;
		} catch (e) {
			return Api.asApiError(e);
		}
	}

	private getQueryParams() {
		let paramsStr = window.location.search;
		if (window.location.hostname === 'localhost' && window.location.hash.includes('?')) {
			const [, p] = window.location.hash.split('?');
			if (p) {
				paramsStr = p;
			}
		}

		const params: Record<string, any> = {};

		paramsStr.split('&').forEach(keyAndValue => {
			const [key, value] = keyAndValue.split('=');
			params[key] = value;
		});

		this.params = params as IParams;
	}
}
