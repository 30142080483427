import { header } from '../../web/styles/colors';
import { BreakPoints } from '../../web/styles/styles';
import { MobileBreakpoint } from '../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clockContainer: {
		alignItems: 'center',
		color: header,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		marginTop: 7,
	},
	clockIcon: {
		marginRight: 9,
	},
	container: {
		display: 'block',
		width: '100%',
	},
	iconContainer: {
		alignItems: 'center',
		backgroundColor: '#00C2F3',
		borderRadius: '50%',
		boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
		display: 'flex',
		height: 106,
		justifyContent: 'center',
		left: 'calc(50% - 53px)',
		overflow: 'hidden',
		position: 'absolute',
		top: -44,
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			display: 'none',
		},
		width: 106,
	},
	iconLevitateAccess: {
		height: 100,
		padding: 10,
		width: 100,
	},
	subText: {
		color: header,
		fontSize: 14,
		marginTop: 73,
		textAlign: 'center',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			marginTop: 35,
		},
	},
	titleText: {
		fontSize: 24,
		marginTop: 14,
		textAlign: 'center',
	},
});
