import { brandPrimary, mention } from '../../../web/styles/colors';
import { BreakPoints, CssSelectors } from '../../../web/styles/styles';
import { MobileBreakpoint, styleSheet as pageStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		minHeight: 430,
		padding: '20px 30px 30px',
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			padding: '20px 10px 30px',
		},
	},
	confirmationContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '60px 0',
		...CssSelectors.allChildren(
			{
				fontSize: '18px',
				fontWeight: 400,
				margin: '50px 0 16px',
			},
			'h2'
		),
	},
	cta: {
		':disabled': {
			opacity: 0.5,
		},
		':hover:disabled': {
			background: brandPrimary,
			cursor: 'default',
			textDecoration: 'none',
		},
	},
	dateAndTime: {
		background: mention,
		borderRadius: 30,
		fontSize: 14,
		margin: '0 10px 0 0',
		padding: '9px 19px',
		[BreakPoints.forMaxWidth(410)]: {
			marginBottom: 10,
		},
	},
	downloadLink: {
		marginTop: 20,
	},
	flexCenter: {
		justifyContent: 'center',
	},
	footer: {
		alignItems: 'flex-start',
		borderTop: `1px solid ${mention}`,
		display: 'flex',
		justifyContent: 'center',
		minHeight: 60,
		paddingTop: 20,
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			borderTop: 'none',
		},
		...CssSelectors.allChildren(
			{
				[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
					display: 'none',
				},
			},
			'.nextCta'
		),
	},
	footerSide: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-start',
		maxWidth: 'calc(50% - 100px)',
		[BreakPoints.forMaxWidth(950)]: {
			':first-child': {
				marginBottom: 15,
				maxWidth: '100%',
			},
		},
		[BreakPoints.forMaxWidth(410)]: {
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
	main: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: 10,
		...CssSelectors.allChildren({
			flexBasis: '50%',
		}),
		[BreakPoints.forMaxWidth(MobileBreakpoint)]: {
			justifyContent: 'center',
			paddingBotton: 0,
			...CssSelectors.allChildren({
				flexBasis: '80%',
			}),
		},
		[BreakPoints.forMaxWidth(500)]: {
			...CssSelectors.allChildren({
				flexBasis: '100%',
			}),
		},
	},
	marginBottom20: {
		marginBottom: 20,
		...CssSelectors.allChildren(
			{
				display: 'block',
				textAlign: 'center',
			},
			'time'
		),
	},
	step3Footer: {
		justifyContent: 'space-between',
		[BreakPoints.forMaxWidth(950)]: {
			alignItems: 'center',
			flexDirection: 'column',
		},
	},
	text: {
		...pageStyles.text,
		fontSize: '14px',
		paddingBottom: 5,
	},
	userInfoContainer: {
		maxWidth: 340,
		...CssSelectors.allChildren(
			{
				paddingTop: 0,
			},
			'button'
		),
	},
});
