import { titles, urgentDate } from '../../web/styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	error: {
		color: urgentDate,
		fontSize: '12px',
		fontStyle: 'italic',
		paddingBottom: 5,
	},
	input: {
		marginBottom: 6,
		marginTop: 2,
	},
	inputLabel: {
		colors: titles,
		display: 'block',
		fontSize: '14px',
		paddingBottom: 4,
	},
});
