import { css } from 'aphrodite';
import * as React from 'react';
import { IAllowInviteeLocationConfig, IMeetingLocation, MeetingLocation } from '../../extViewmodels/sdk';
import { useEventLogging } from '../../models/Logging';
import { RadioButton } from '../../web/components/RadioButton';
import { urgentDate } from '../../web/styles/colors';
import { useMeeting } from '../contexts/meeting';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	setInviteeSelectedMeetingLocation: React.Dispatch<React.SetStateAction<MeetingLocation>>;
	inviteeSelectedMeetingLocation: MeetingLocation;
}

export const ScheduleMeetingLocation: React.FC<IProps> = ({
	className = '',
	setInviteeSelectedMeetingLocation,
	inviteeSelectedMeetingLocation,
}) => {
	const { meetingVm } = useMeeting();
	const logger = useEventLogging();
	// @ts-ignore

	const onLocationChange = (location: IMeetingLocation) => async () => {
		try {
			// @ts-ignore
			setInviteeSelectedMeetingLocation(location._type);
			switch (location._type) {
				case MeetingLocation.Phone:
					await meetingVm.updateLocationPhone(location);
					break;
				case MeetingLocation.InPerson:
					await meetingVm.updateLocationInPerson(location);
					break;
				case MeetingLocation.Virtual:
					await meetingVm.updateLocationVirtual(location);
					break;
				default:
					break;
			}
		} catch (err) {
			// @ts-ignore
			logger.logApiError('SchedulerUpdateSelectedLocation-Error', err);
		}
	};

	const getDescription = (type: MeetingLocation) => {
		switch (type) {
			case MeetingLocation.Phone:
				return 'Phone Call';
			case MeetingLocation.InPerson:
				return 'In Person';
			case MeetingLocation.Virtual:
				return 'Virtual';
			default:
				return '';
		}
	};

	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<label className={css(styleSheet.inputLabel)} htmlFor='meeting-guests-input'>
				Meeting Location
				<span style={{ color: urgentDate }}>*</span>
			</label>
			{(meetingVm.locationConfig as IAllowInviteeLocationConfig)?.locations?.map((location, i) => {
				return (
					<div key={`location-${i}`}>
						<RadioButton
							checked={inviteeSelectedMeetingLocation === location._type}
							id={`meeting-config-location-${location._type}`}
							name='meeting-config-location'
							onChange={onLocationChange(location)}
						>
							{/* @ts-ignore */}
							<span>{getDescription(location._type)}</span>
						</RadioButton>
					</div>
				);
			})}
		</div>
	);
};
