import { css } from 'aphrodite';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IMeetingConfigInfo, IMeetingConfigLanding } from '../../../extViewmodels';
import { Button } from '../../../web/components/Button';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { ClockIcon } from '../../../web/components/svgs/icons/ClockIcon';
import { grayIconFill } from '../../../web/styles/colors';
import { baseStyleSheet } from '../../../web/styles/styles';
import { ScheduleMeetingHeaderHost } from '../../ScheduleMeetingHeaderHost';
import { ScheduleMeetingNotFound } from '../../ScheduleMeetingNotFound';
import { getDurationText } from '../../UiUtils';
import { useLanding } from '../../contexts/landing';
import { styleSheet as pageStyles } from '../../styles/styles';
import { styleSheet } from './styles';

export const ScheduleMeetingLanding: React.FC = () => {
	const { landingVm } = useLanding();
	const location = useLocation();
	const history = useHistory();
	// @ts-ignore
	const [landing, setLanding] = React.useState<IMeetingConfigLanding>(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [hasError, setHasError] = React.useState(false);

	React.useEffect(() => {
		setIsLoading(true);
		landingVm
			.fetchMeetings()
			.then(value => {
				// @ts-ignore
				setLanding(value);
				setIsLoading(false);
			})
			.catch(() => {
				setHasError(true);
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onBookMeeting = (meeting: IMeetingConfigInfo) => () => {
		const path = location?.search
			? `/${meeting.shortCode}/${meeting.vanityPath}${location.search}`
			: `/${meeting.shortCode}/${meeting.vanityPath}`;
		history.push(path);
	};

	const renderContent = () => {
		if (isLoading) {
			return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />;
		}

		if (hasError || !landing?.meetingConfigs?.length) {
			return <ScheduleMeetingNotFound text={hasError ? 'Invalid Meeting URL' : 'No meetings found'} />;
		}

		return (
			<div className={css(pageStyles.container, styleSheet.container)}>
				<div className={css(pageStyles.header, styleSheet.header)}>
					<div className={css(pageStyles.headerCol)}>
						<ScheduleMeetingHeaderHost host={landing.creatorName} company={landing.companyName} isReschedule={false} />
					</div>
					<div className={css(pageStyles.headerCol, pageStyles.headerColRight)}>
						<div className={css(styleSheet.instructions)}>
							<p>Welcome to my schedule a meeting calendar. Please select from the following meeting time types.</p>
						</div>
					</div>
				</div>
				<div>
					<div className={css(styleSheet.meetings)}>
						{landing.meetingConfigs.map(meeting => {
							return (
								<div className={css(styleSheet.card)} key={`/${meeting.shortCode}/${meeting.vanityPath}`}>
									<div className={css(styleSheet.meetingName)}>{meeting.name}</div>
									<span className={css(pageStyles.label, styleSheet.label)}>
										<ClockIcon className={css(styleSheet.icon)} fillColor={grayIconFill} />
										<span className={css(styleSheet.timeText)}>{getDurationText(meeting.duration)}</span>
									</span>
									<Button
										className={`${css(styleSheet.cta)} nextCta`}
										label='Book Meeting'
										onClick={onBookMeeting(meeting)}
										size='small'
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	return <div className={css(pageStyles.background)}>{renderContent()}</div>;
};
