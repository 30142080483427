import { SvgIcon } from '../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

export const BookingTigerIcon: React.FC = () => {
	return (
		<SvgIcon height={106} width={106}>
			<circle cx='53' cy='53' r='53' fill='#046CB6' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.8293 59.5455L16 65.4848H22.8293L17.7073 70.5758H24.5366C24.5366 70.5758 27.5947 78.951 31.3659 81.6061C35.137 84.2611 45.0244 85 45.0244 85L51 78.2121L27.0976 57L22.8293 59.5455Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M83.1707 59.5455L90 65.4848H83.1707L88.2927 70.5758H81.4634C81.4634 70.5758 78.4053 78.951 74.6341 81.6061C70.863 84.2611 60.9756 85 60.9756 85L55 78.2121L78.9024 57L83.1707 59.5455Z'
				fill='white'
			/>
			<circle cx='53.5' cy='78.5' r='11.5' fill='#063E66' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M45.9996 87.9993C47.3969 90.3909 50.0087 92 53 92C55.9913 92 58.6031 90.3909 60.0004 87.9993H45.9996Z'
				fill='#F89143'
			/>
			<rect x='25' y='35' width='58' height='37' rx='18.5' fill='#E78236' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31.7647 73.3372C30.2387 68.7413 33.6602 64 38.5028 64H45.2378C49.3861 64 52.7491 67.3629 52.7491 71.5113V71.5113C52.7491 76.0223 48.8009 79.5158 44.3234 78.9667L37.6385 78.1468C34.9159 77.8129 32.6291 75.9404 31.7647 73.3372V73.3372Z'
				fill='#FFA25C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M73.7336 73.3372C75.2596 68.7413 71.8381 64 66.9955 64H60.2605C56.1122 64 52.7492 67.3629 52.7492 71.5113V71.5113C52.7492 76.0223 56.6974 79.5158 61.1749 78.9667L67.8598 78.1468C70.5824 77.8129 72.8692 75.9404 73.7336 73.3372V73.3372Z'
				fill='#FFA25C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M46 64H58L54.8421 70L52.3158 71L49.7895 70L46 64Z'
				fill='#063E66'
			/>
			<rect x='43' y='39' width='19' height='4.58333' rx='2' fill='#063E66' />
			<rect x='45.375' y='45.4167' width='15.0417' height='4.58333' rx='2' fill='#063E66' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M75.1886 61.082C74.8351 60.4716 75.0445 59.6902 75.6558 59.3383L80.085 56.7885C80.6942 56.4379 81.4722 56.6463 81.8244 57.2546V57.2546C82.1778 57.865 81.9685 58.6464 81.3571 58.9983L76.9279 61.5481C76.3188 61.8987 75.5407 61.6903 75.1886 61.082V61.082Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M75.1886 65.3285C74.8351 64.7181 75.0445 63.9367 75.6558 63.5848L80.085 61.035C80.6942 60.6844 81.4722 60.8928 81.8244 61.5011V61.5011C82.1778 62.1115 81.9685 62.8929 81.3571 63.2448L76.9279 65.7946C76.3188 66.1452 75.5407 65.9368 75.1886 65.3285V65.3285Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31.694 60.2327C32.0475 59.6223 31.8381 58.8409 31.2268 58.489L26.7975 55.9392C26.1884 55.5885 25.4104 55.797 25.0582 56.4052V56.4052C24.7047 57.0157 24.9141 57.7971 25.5254 58.149L29.9547 60.6988C30.5638 61.0494 31.3418 60.8409 31.694 60.2327V60.2327Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31.694 64.4792C32.0475 63.8688 31.8381 63.0874 31.2268 62.7355L26.7975 60.1857C26.1884 59.8351 25.4104 60.0435 25.0582 60.6518V60.6518C24.7047 61.2622 24.9141 62.0436 25.5254 62.3955L29.9547 64.9453C30.5638 65.2959 31.3418 65.0875 31.694 64.4792V64.4792Z'
				fill='#063E66'
			/>
			<rect x='64' y='69' width='23' height='2' rx='1' fill='#063E66' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M63.7556 73.1099C63.8372 72.6483 64.2774 72.3401 64.7391 72.4212L83.1984 75.6666C83.6607 75.7479 83.9694 76.1888 83.8877 76.6511V76.6511C83.8061 77.1127 83.3659 77.4209 82.9042 77.3397L64.4449 74.0943C63.9826 74.0131 63.6739 73.5721 63.7556 73.1099V73.1099Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M64.1383 76.234C64.3066 75.7967 64.7972 75.5783 65.2349 75.7458L78.757 80.9213C79.1958 81.0893 79.415 81.5815 79.2462 82.02V82.02C79.0778 82.4573 78.5872 82.6758 78.1496 82.5083L64.6275 77.3328C64.1886 77.1648 63.9694 76.6725 64.1383 76.234V76.234Z'
				fill='#063E66'
			/>
			<rect x='64' y='69' width='23' height='1.75' rx='0.875' fill='#063E66' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M64.1468 72.4453C64.2267 71.9623 64.6882 71.6393 65.1695 71.7297L82.8674 75.0539C83.3341 75.1415 83.6453 75.5862 83.5677 76.0547V76.0547C83.4877 76.5378 83.0262 76.8607 82.545 76.7703L64.8471 73.4462C64.3803 73.3585 64.0692 72.9139 64.1468 72.4453V72.4453Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M64.5059 75.6806C64.6732 75.2163 65.1936 74.9849 65.6504 75.1717L78.6135 80.4723C79.0438 80.6483 79.2589 81.1321 79.1013 81.5694V81.5694C78.934 82.0337 78.4136 82.2651 77.9568 82.0783L64.9937 76.7777C64.5635 76.6018 64.3483 76.1179 64.5059 75.6806V75.6806Z'
				fill='#063E66'
			/>
			<rect width='23' height='1.75' rx='0.875' transform='matrix(-1 0 0 1 42 69)' fill='#063E66' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M41.8532 72.4453C41.7733 71.9623 41.3118 71.6393 40.8305 71.7297L23.1326 75.0539C22.6659 75.1415 22.3547 75.5862 22.4323 76.0547V76.0547C22.5123 76.5378 22.9738 76.8607 23.455 76.7703L41.1529 73.4462C41.6197 73.3585 41.9308 72.9139 41.8532 72.4453V72.4453Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M41.4941 75.6806C41.3268 75.2163 40.8064 74.9849 40.3496 75.1717L27.3865 80.4723C26.9562 80.6483 26.7411 81.1321 26.8987 81.5694V81.5694C27.066 82.0337 27.5864 82.2651 28.0432 82.0783L41.0063 76.7777C41.4365 76.6018 41.6517 76.1179 41.4941 75.6806V75.6806Z'
				fill='#063E66'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M46 86L48 81L50 86H46Z' fill='white' />
			<path fillRule='evenodd' clipRule='evenodd' d='M56 86L58 81L60 86H56Z' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M60.8261 62L79 49.0413C79 49.0413 68.2609 46.6116 63.3043 49.8512C58.3478 53.0909 60.8261 62 60.8261 62Z'
				fill='white'
			/>
			<ellipse rx='6.68016' ry='7.7154' transform='matrix(-1 0 0 1 68.6802 56.7154)' fill='#063E66' />
			<ellipse rx='3.29412' ry='3.55556' transform='matrix(-1 0 0 1 67.7806 57.3304)' fill='#F84343' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M45.1739 61.3651L27 47.1429C27 47.1429 37.7391 44.4762 42.6957 48.0317C47.6522 51.5873 45.1739 61.3651 45.1739 61.3651Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M69.4701 35.5534C69.4701 35.5534 74.829 35.2948 77.4913 34.44C80.1536 33.5852 84.2455 30.8096 84.2455 30.8096C84.2455 30.8096 87.6563 42.0053 79.7604 43.9989C71.8644 45.9925 69.4701 35.5534 69.4701 35.5534Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M69.5345 35.0086C69.5345 35.0086 74.1631 34.7798 76.4638 34.0468C78.7645 33.3138 82.3028 30.9407 82.3028 30.9407C82.3028 30.9407 85.2308 40.4817 78.4082 42.1939C71.5857 43.9061 69.5345 35.0086 69.5345 35.0086Z'
				fill='#F89143'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M36.5299 35.5534C36.5299 35.5534 31.171 35.2948 28.5087 34.44C25.8464 33.5852 21.7545 30.8096 21.7545 30.8096C21.7545 30.8096 18.3437 42.0053 26.2396 43.9989C34.1356 45.9925 36.5299 35.5534 36.5299 35.5534Z'
				fill='#063E66'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M36.4655 35.0086C36.4655 35.0086 31.8369 34.7798 29.5362 34.0468C27.2355 33.3138 23.6972 30.9407 23.6972 30.9407C23.6972 30.9407 20.7692 40.4817 27.5918 42.1939C34.4143 43.9061 36.4655 35.0086 36.4655 35.0086Z'
				fill='#F89143'
			/>
			<circle cx='49' cy='72' r='1' fill='#063E66' />
			<circle cx='45' cy='73' r='1' fill='#063E66' />
			<ellipse rx='0.833333' ry='1' transform='matrix(-1 0 0 1 57.8334 71)' fill='#063E66' />
			<ellipse rx='0.833333' ry='1' transform='matrix(-1 0 0 1 61.1667 73)' fill='#063E66' />
			<ellipse cx='37.3198' cy='56.7154' rx='6.68016' ry='7.7154' fill='#063E66' />
			<ellipse cx='38.2194' cy='57.3304' rx='3.29412' ry='3.55556' fill='#F84343' />
			<path fillRule='evenodd' clipRule='evenodd' d='M40 19L51 28H40L40 19Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M41 28L46 33H41L41 28Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M46 28L51 33H46L46 28Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M60 28L55 33H60L60 28Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M65 28L60 33H65L65 28Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M66 19L55 28H66L66 19Z' fill='#E7C309' />
			<circle cx='53' cy='16' r='2' fill='#F3CE0D' />
			<circle cx='65' cy='16' r='2' fill='#F3CE0D' />
			<circle cx='42' cy='16' r='2' fill='#F3CE0D' />
			<path fillRule='evenodd' clipRule='evenodd' d='M52.5 19L58 28H47L52.5 19Z' fill='#F9D519' />
			<path fillRule='evenodd' clipRule='evenodd' d='M53 28L55 33H51L53 28Z' fill='#F9D519' />
		</SvgIcon>
	);
};
