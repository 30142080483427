import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const BookingGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`bounced-emails-graphic ${className}`} height={126} width={191}>
			<path
				d='M184.301 83.8607C182.922 85.3724 181.212 86.4977 179.357 87.3714H179.354C179.18 87.4545 179.005 87.5354 178.828 87.6118H178.826L178.824 87.614L178.822 87.6118H178.819C178.819 87.6124 178.819 87.613 178.819 87.6134C178.818 87.6138 178.818 87.614 178.817 87.614H178.815L178.813 87.6118C178.81 87.614 178.81 87.614 178.808 87.6118L178.806 87.614L178.804 87.6118H178.802C178.799 87.614 178.799 87.614 178.797 87.6118H178.795C177.553 88.1509 176.277 88.6085 174.976 88.9819C167.325 91.16 159.283 91.5814 151.448 90.215C151.298 90.1881 151.15 90.1611 151 90.1342V71.9159C151.148 71.853 151.298 71.7901 151.448 71.7295C152.426 71.3229 153.416 70.9493 154.418 70.6086C155.86 70.1145 157.321 69.6907 158.803 69.3373C163.638 68.1632 168.626 67.7585 173.586 68.1379C174.488 68.2053 175.388 68.3026 176.285 68.4299C178.164 68.6927 180.133 69.1509 181.866 69.946H181.868C182.121 70.0651 182.37 70.1886 182.616 70.3212C184.156 71.159 185.454 72.3112 186.247 73.8902C186.531 74.4573 186.736 75.0613 186.855 75.6849V75.6894C186.9 75.905 186.931 76.1229 186.956 76.3385C187.238 78.9979 186.139 81.8437 184.301 83.8607Z'
				fill='#F2F2F2'
			/>
			<path
				d='M186.788 77.5611C186.766 77.5658 186.743 77.5684 186.721 77.5688C182.904 77.7134 179.093 77.928 175.287 78.2127H175.28C175.265 78.2146 175.251 78.2146 175.236 78.2165C171.328 78.5095 167.428 78.8764 163.537 79.3172C163.372 79.3365 163.209 79.3557 163.047 79.3731C160.355 79.6834 157.669 80.0278 154.988 80.4063C153.805 80.574 152.624 80.7488 151.446 80.9306C151.296 80.9537 151.149 80.9749 151 81V80.4159C151.149 80.3909 151.299 80.3697 151.446 80.3465C155.371 79.7451 159.31 79.2202 163.263 78.7717C164.256 78.656 165.252 78.5468 166.248 78.444C169.127 78.1433 172.01 77.8837 174.897 77.6652C175.109 77.6498 175.318 77.6344 175.53 77.619C179.226 77.3472 182.927 77.1409 186.634 77.0002C186.652 76.9995 186.67 77.0008 186.688 77.004C187.044 77.031 187.12 77.4937 186.788 77.5611Z'
				fill='white'
			/>
			<path
				d='M178.641 87.3585C173.958 87.3924 169.344 85.4321 166.032 82.1874C165.097 81.2684 164.274 80.2455 163.578 79.1393C163.354 78.7858 162.832 79.1756 163.054 79.5261C165.626 83.599 169.911 86.5004 174.635 87.5672C175.977 87.8673 177.349 88.0123 178.725 87.9992C179.145 87.9961 179.058 87.3554 178.641 87.3585V87.3585Z'
				fill='white'
			/>
			<path
				d='M158 70C157.963 70.1297 157.867 70.2273 157.749 70.2562C156.767 70.5468 155.818 70.9653 154.922 71.5034C153.613 72.2842 152.424 73.2988 151.402 74.5079C151.347 74.5692 151.295 74.6304 151.245 74.6939C151.161 74.7937 151.08 74.8957 151 75V74.0113C151.129 73.8571 151.263 73.7052 151.402 73.5601C152.209 72.6873 153.103 71.9233 154.066 71.2834C155.359 70.7846 156.671 70.3568 158 70Z'
				fill='white'
			/>
			<path
				d='M181.593 70.0295C178.842 71.3565 176.541 73.6342 175.023 76.5312C174.959 76.7037 175.031 76.9019 175.184 76.9756C175.338 77.0438 175.512 76.9644 175.58 76.7953C177.028 74.0592 179.213 71.9101 181.82 70.6579C182.177 70.4864 181.951 69.8576 181.593 70.0295L181.593 70.0295Z'
				fill='white'
			/>
			<path
				d='M190.654 52.0429C190.132 53.9951 189.13 55.7636 187.885 57.3771L187.883 57.3794C187.768 57.5299 187.648 57.6826 187.525 57.8287V57.8309C186.66 58.8778 185.727 59.8678 184.731 60.7946C181.15 64.1497 177.105 66.991 172.716 69.2342C165.811 72.78 158.093 74.8761 150.45 74.9956C150.392 74.9978 150.333 74.9978 150.275 74.9978C150.182 75 150.09 75 150 75V64.9402C150.146 64.6658 150.297 64.3913 150.45 64.1191C152.437 60.6052 154.859 57.3474 157.661 54.4201C157.729 54.347 157.799 54.274 157.868 54.2032C158.922 53.1098 160.026 52.0687 161.182 51.0801C165.628 47.2369 170.766 44.2453 176.331 42.2598C178.125 41.6268 180.093 41.1199 182.007 41.0203C182.288 41.0048 182.567 40.9982 182.849 41.0004C184.611 41.0248 186.302 41.4342 187.745 42.448C188.262 42.8129 188.726 43.2455 189.123 43.7339C189.263 43.9044 189.391 44.0792 189.515 44.2585V44.2607C191.005 46.4519 191.352 49.4378 190.654 52.0429Z'
				fill='#F2F2F2'
			/>
			<path
				d='M188.904 43.5484V43.5506C188.888 43.5649 188.87 43.5775 188.851 43.5882C185.57 45.4914 182.331 47.4639 179.134 49.5056C179.129 49.5078 179.127 49.51 179.123 49.5122C179.11 49.5211 179.096 49.5292 179.083 49.5365C175.796 51.6365 172.555 53.8071 169.361 56.0485C169.228 56.1414 169.093 56.2364 168.958 56.3315C166.752 57.8854 164.57 59.4725 162.41 61.0928C158.332 64.1564 154.343 67.3336 150.443 70.6242C150.295 70.748 150.148 70.874 150 71V70.1401C150.146 70.0141 150.295 69.8881 150.443 69.7644C151.937 68.5066 153.444 67.2666 154.965 66.0442C155.419 65.6794 155.876 65.3147 156.332 64.9544C160.389 61.7338 164.536 58.6333 168.774 55.6529H168.776C169.609 55.0671 170.444 54.4865 171.281 53.911C173.657 52.2797 176.059 50.6874 178.489 49.1342C178.664 49.0215 178.841 48.9088 179.018 48.796C182.128 46.8155 185.279 44.8998 188.47 43.0489C188.485 43.0394 188.501 43.0313 188.518 43.0246C188.851 42.8919 189.163 43.3296 188.904 43.5484Z'
				fill='white'
			/>
			<path
				d='M187.448 56.409C183.132 58.5503 177.9 58.9237 173.24 57.5919C171.923 57.213 170.659 56.6937 169.471 56.0443C169.09 55.8372 168.799 56.4122 169.176 56.6175C173.558 59.0042 178.949 59.5984 183.845 58.397C185.234 58.0533 186.575 57.5607 187.841 56.929C188.228 56.737 187.833 56.2183 187.448 56.409V56.409Z'
				fill='white'
			/>
			<path
				d='M156.248 63.7229C156.03 62.6027 155.955 61.4632 156.025 60.3272C156.183 57.7365 157.051 55.2236 158.549 53.0197C159.645 51.963 160.794 50.9564 161.995 50C162.018 50.1273 161.966 50.2562 161.857 50.3391C160.984 51.0682 160.205 51.8873 159.538 52.7801C157.153 55.962 156.222 59.8697 156.944 63.67C156.968 63.7576 156.94 63.8502 156.87 63.9145C156.769 63.9956 156.627 64.0212 156.499 63.9818C156.371 63.9424 156.276 63.8438 156.248 63.7229V63.7229Z'
				fill='white'
			/>
			<path
				d='M183.419 40.1144C181.356 42.5565 180.158 45.5675 180 48.7091C180.016 48.8833 180.175 49.0125 180.357 48.999C180.536 48.9805 180.669 48.8297 180.658 48.6564C180.823 45.6819 181.966 42.8343 183.923 40.5244C184.192 40.2077 183.688 39.797 183.419 40.1144V40.1144Z'
				fill='white'
			/>
			<path
				d='M159.935 47.6347V47.6369C159.919 47.8291 159.901 48.019 159.881 48.209V48.2134C159.725 49.5654 159.481 50.9059 159.151 52.2266C158.994 52.8746 158.824 53.5182 158.637 54.1617C158.615 54.2444 158.59 54.3271 158.566 54.4075V54.4097C158.017 56.2814 157.363 58.1204 156.604 59.9178C155.209 63.2271 153.481 66.3878 151.449 69.352C151.303 69.5687 151.153 69.7855 151 70V35.0074C151.15 34.9985 151.299 34.9985 151.449 35.0029C151.521 35.0029 151.592 35.0052 151.664 35.0096C152.298 35.0428 152.923 35.1639 153.523 35.3694C153.734 35.4387 153.938 35.5169 154.14 35.604C156.588 36.6721 158.512 39.0273 159.347 41.6171C159.973 43.5567 160.098 45.5968 159.935 47.6347Z'
				fill='#F2F2F2'
			/>
			<path
				d='M152.988 35.397C152.983 35.42 152.975 35.4422 152.964 35.4627C152.099 37.3101 151.254 39.1668 150.427 41.0329C150.284 41.3545 150.141 41.6784 150 42V40.4038C150.141 40.0846 150.283 39.7654 150.427 39.4461C151.066 38.0237 151.715 36.6074 152.375 35.1975C152.382 35.181 152.391 35.1653 152.4 35.1505C152.597 34.8407 153.084 35.052 152.988 35.397Z'
				fill='white'
			/>
			<path
				d='M159.957 47.4643C159.917 47.5405 159.876 47.6166 159.832 47.6928C159.176 48.9105 158.396 50.0469 157.506 51.0814C155.796 53.0593 153.737 54.658 151.446 55.7858C151.299 55.862 151.15 55.9334 151 56V55.2266C151.15 55.1552 151.299 55.0815 151.446 55.0053C154.762 53.3192 157.516 50.5946 159.341 47.1955C159.394 47.0973 159.485 47.0285 159.589 47.0071C159.694 46.9856 159.802 47.0135 159.885 47.0836C159.942 47.124 159.982 47.1868 159.995 47.2582C160.008 47.3296 159.995 47.4038 159.957 47.4643V47.4643Z'
				fill='white'
			/>
			<path
				d='M151 7.26606V24H150.551V23.8217H0.448737V24H0V7.26606C0.00401226 3.25476 3.31662 0.00393743 7.40416 0H143.596C147.683 0.00393743 150.996 3.25476 151 7.26606V7.26606Z'
				fill='#046CB6'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.40416 0H143.596C147.683 0.00400567 150.996 3.31117 151 7.392V104.608C150.996 108.689 147.683 111.996 143.596 112H7.40416C3.31662 111.996 0.00401226 108.689 0 104.608V7.392C0.00401226 3.31117 3.31662 0.00400567 7.40416 0ZM143.596 111.552C147.435 111.547 150.546 108.441 150.551 104.608V7.392C150.546 3.55896 147.435 0.452892 143.596 0.448H7.40416C3.56482 0.452891 0.453636 3.55896 0.448737 7.392V104.608C0.453636 108.441 3.56482 111.547 7.40416 111.552H143.596Z'
				fill='#3F3D56'
			/>
			<circle cx='30.5' cy='12.5' r='4.5' fill='white' />
			<circle cx='120.5' cy='12.5' r='4.5' fill='white' />
			<path
				d='M89.6407 62H62.3593C59.9529 61.9974 58.0027 60.1025 58 57.7643V45.2357C58.0027 42.8975 59.9529 41.0026 62.3593 41H89.6407C92.0471 41.0026 93.9973 42.8975 94 45.2357V57.7643C93.9973 60.1025 92.0471 61.9974 89.6407 62V62Z'
				fill='#89C947'
			/>
			<path
				d='M89.5413 94H62.4587C59.9974 93.9973 58.0028 92.0686 58 89.6887V77.3114C58.0028 74.9314 59.9974 73.0027 62.4587 73H89.5413C92.0026 73.0027 93.9972 74.9314 94 77.3114V89.6887C93.9972 92.0686 92.0026 93.9973 89.5413 94Z'
				fill='#F2F2F2'
			/>
			<path
				d='M43.5413 94H16.4587C13.9974 93.9973 12.0028 92.0686 12 89.6887V77.3114C12.0028 74.9314 13.9974 73.0027 16.4587 73H43.5413C46.0026 73.0027 47.9972 74.9314 48 77.3113V89.6886C47.9972 92.0686 46.0026 93.9973 43.5413 94V94Z'
				fill='#F2F2F2'
			/>
			<path
				d='M43.5413 62H16.4587C13.9974 61.9973 12.0028 60.0686 12 57.6887V45.3114C12.0028 42.9314 13.9974 41.0027 16.4587 41H43.5413C46.0026 41.0027 47.9972 42.9314 48 45.3113V57.6886C47.9972 60.0686 46.0026 61.9973 43.5413 62V62Z'
				fill='#F2F2F2'
			/>
			<path
				d='M134.541 94H107.459C104.997 93.9973 103.003 92.0686 103 89.6887V77.3114C103.003 74.9314 104.997 73.0027 107.459 73H134.541C137.003 73.0027 138.997 74.9314 139 77.3113V89.6886C138.997 92.0686 137.003 93.9973 134.541 94V94Z'
				fill='#F2F2F2'
			/>
			<path
				d='M134.541 62H107.459C104.997 61.9973 103.003 60.0686 103 57.6887V45.3114C103.003 42.9314 104.997 41.0027 107.459 41H134.541C137.003 41.0027 138.997 42.9314 139 45.3114V57.6887C138.997 60.0686 137.003 61.9973 134.541 62Z'
				fill='#F2F2F2'
			/>
			<ellipse cx='75.5' cy='52' rx='7.5' ry='7' fill='white' />
			<path
				d='M74.7402 54C74.5585 54.0003 74.3817 53.9524 74.2364 53.8637L74.2274 53.8582L72.3303 52.6753C71.9608 52.4447 71.8907 52.0139 72.1739 51.713C72.457 51.412 72.986 51.355 73.3555 51.5856L74.5843 52.3529L77.4882 49.2687C77.6242 49.1243 77.8251 49.0297 78.0467 49.0059C78.2683 48.9821 78.4925 49.0309 78.6699 49.1417L78.6518 49.1617L78.6704 49.142C79.0393 49.3729 79.109 49.8034 78.8263 50.1044L75.4107 53.7321C75.2506 53.9016 75.0027 54.0006 74.7402 54V54Z'
				fill='#89C947'
			/>
			<path
				d='M124.373 85.2016C124.851 84.6761 125.073 83.9646 124.979 83.2587C124.885 82.5528 124.485 81.9249 123.887 81.5441L119.877 60L115 61.8196L120.475 82.4245C119.893 83.5016 120.204 84.8465 121.198 85.555C122.193 86.2635 123.557 86.1117 124.373 85.2016V85.2016Z'
				fill='#A0616A'
			/>
			<path
				d='M85.2025 52.3809C85.215 52.5193 85.2167 52.6586 85.2076 52.7974L94.9699 60.3988L97.7383 59.2487L100 63.2488L95.2947 65.7667C94.5138 66.1845 93.5541 66.0307 92.9391 65.3893L83.1811 55.2115C82.1236 55.4528 81.0287 55.0068 80.4314 54.0915C79.8341 53.1761 79.859 51.9823 80.4939 51.0933C81.1288 50.2042 82.2412 49.8053 83.2878 50.0915C84.3344 50.3777 85.0968 51.2892 85.2025 52.3809H85.2025Z'
				fill='#A0616A'
			/>
			<path d='M106.999 123H104.289L103 115L107 115L106.999 123V123Z' fill='#A0616A' />
			<path
				d='M109 126L100 126V125.874C100 124.846 100.369 123.861 101.026 123.135C101.683 122.408 102.574 122 103.503 122H103.503L109 122L109 126Z'
				fill='#2F2E41'
			/>
			<path d='M117.999 123H115.289L114 115L118 115L117.999 123V123Z' fill='#A0616A' />
			<path
				d='M118 126L109 126V125.874C109 124.846 109.369 123.861 110.026 123.135C110.683 122.408 111.574 122 112.503 122H112.503L118 122L118 126Z'
				fill='#2F2E41'
			/>
			<path
				d='M104.415 121.957C103.893 121.957 103.456 121.568 103.407 121.058L101 89.3429L101.114 89.3338L117.678 88L117.683 88.1153L118.999 120.97C119.01 121.239 118.907 121.502 118.716 121.696C118.525 121.89 118.262 122 117.986 122H114.738C114.237 122.002 113.81 121.644 113.736 121.158L109.326 94.6173C109.317 94.5605 109.265 94.5199 109.206 94.5241C109.148 94.5282 109.102 94.5757 109.102 94.6333L108.902 120.838C108.897 121.363 108.477 121.795 107.941 121.823L104.47 121.955C104.451 121.956 104.433 121.957 104.415 121.957Z'
				fill='#2F2E41'
			/>
			<circle cx='107.5' cy='42.5' r='5.5' fill='#A0616A' />
			<path
				d='M108.043 91C105.58 90.9929 103.129 90.6676 100.755 90.0328C100.284 89.8996 99.9735 89.4637 100.012 88.9888C100.771 77.9186 100.949 69.3279 99.354 63.384C98.6741 60.8506 98.9828 58.1825 100.201 56.0638C102.034 52.8753 105.403 50.9347 108.997 51.0017V51.0017C109.169 51.0048 109.342 51.0108 109.517 51.0195C114.971 51.2922 119.221 56.0832 118.991 61.6995L117.894 88.4693C117.886 88.8686 117.631 89.224 117.248 89.3701C114.305 90.4451 111.187 90.9972 108.043 91V91Z'
				fill='#3F3D56'
			/>
			<path
				d='M101.394 65.5078L97.2895 60.2703C97.0532 59.9688 96.9546 59.5751 97.0195 59.1918C97.0844 58.8085 97.3063 58.4736 97.6274 58.2743L103.188 54.8244C104.72 53.5376 106.963 53.7842 108.202 55.3756C109.442 56.967 109.211 59.303 107.686 60.5981L103.34 65.5634C103.088 65.851 102.729 66.0102 102.355 65.9995C101.981 65.9888 101.631 65.8093 101.394 65.5078H101.394Z'
				fill='#3F3D56'
			/>
			<path
				d='M113.733 64.8892C113.41 64.7389 113.167 64.4496 113.066 64.0968L111.32 58.0088C110.53 56.2536 111.253 54.1637 112.935 53.3368C114.617 52.5099 116.624 53.2579 117.422 55.009L120.798 60.3242C120.993 60.6312 121.05 61.0114 120.955 61.3658C120.859 61.7202 120.62 62.0137 120.3 62.1697L114.744 64.8805C114.424 65.0367 114.055 65.0399 113.733 64.8892V64.8892Z'
				fill='#3F3D56'
			/>
			<path
				d='M110.098 46.9244L106.134 46.0005C105.726 45.9052 105.234 45.7234 105.178 45.3002C105.102 44.7315 105.905 44.3354 105.831 43.7664C105.759 43.2153 105.027 43.1443 104.505 42.9816C103.495 42.6205 102.948 41.5076 103.27 40.4674C102.706 41.278 101.415 41.3474 100.683 40.6907C99.95 40.034 99.8095 38.8426 100.249 37.9554C100.732 37.0716 101.599 36.4752 102.584 36.3502C103.561 36.2374 104.548 36.4249 105.419 36.8884C105.477 36.2603 106.247 36.0056 106.867 36C109.505 36.0313 111.834 37.7622 112.667 40.3113C113.427 42.8059 112.919 45.5404 110.792 47L110.098 46.9244Z'
				fill='#2F2E41'
			/>
			<path
				d='M151.775 126H66.2245C66.1005 126 66 125.776 66 125.5C66 125.224 66.1005 125 66.2245 125H151.775C151.899 125 152 125.224 152 125.5C152 125.776 151.899 126 151.775 126Z'
				fill='#3F3D56'
			/>
		</SvgIcon>
	);
};
