import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { TextInput } from '../../web/components/TextInput';
import { urgentDate } from '../../web/styles/colors';
import { styleSheet as pageStyles } from '../styles/styles';
import { IUserInputFieldData, UserInput } from '../types';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	fieldData: IUserInputFieldData;
	getAnyErrors?(id: UserInput): string;
	onInputBlur(id: UserInput): void;
	onInputChange(id: UserInput, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
	styles?: StyleDeclarationValue[];
}

export const ScheduleMeetingInput: React.FC<IProps> = props => {
	const { className, fieldData, styles = [] } = props;
	const onInputBlur = () => props.onInputBlur(fieldData.id);
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		props.onInputChange(fieldData.id, e);
	return (
		<div className={`${css(styleSheet.container, ...styles)} schedule-meeting-input ${className || ''}`}>
			<label className={css(pageStyles.inputLabel)} htmlFor={`user-${fieldData.id}`}>
				{fieldData.text}
				{fieldData.required && <span style={{ color: urgentDate }}>*</span>}
				{props.getAnyErrors ? (
					<span className={css(styleSheet.inputError)}>{props.getAnyErrors(fieldData.id)}</span>
				) : null}
			</label>
			{fieldData.type === 'text' ? (
				<TextInput
					inputId={`user-${fieldData.id}`}
					onBlur={onInputBlur}
					onChange={onInputChange}
					type='text'
					value={fieldData.value}
				/>
			) : (
				<textarea
					className={css(styleSheet.textArea)}
					id={`user-${fieldData.id}`}
					onBlur={onInputBlur}
					onChange={onInputChange}
					value={fieldData.value}
					maxLength={fieldData?.maxLength}
				/>
			)}
		</div>
	);
};
