import { createContext } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ILandingContext } from '../types';
import { Landing } from '../viewmodels/landing';

// @ts-ignore
const LandingContext = createContext<ILandingContext>(null);

export const LandingProvider: React.FC = ({ children }) => {
	const location = useLocation();
	return <LandingContext.Provider value={{ landingVm: new Landing(location) }}>{children}</LandingContext.Provider>;
};

export const useLanding = () => {
	const context = React.useContext(LandingContext);
	if (!context) {
		throw new Error('useLanding can only be used within the <ScheduleMeetingLanding /> component');
	}
	return context;
};
