import { useLambda } from '../../models/hooks/useLambda';
import { Button } from '../../web/components/Button';
import { LoadingSpinner } from '../../web/components/LoadingSpinner';
import { ITimezoneData, TimezonePicker } from '../../web/components/TimezonePicker';
import { baseStyleSheet } from '../../web/styles/styles';
import { getSelectedDateAsString } from '../UiUtils';
import { CheckmarkIcon } from '../icons/CheckmarkIcon';
import { styleSheet as pageStyles } from '../styles/styles';
import { IAvailabilitySlot } from '../types';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import moment from 'moment';
import { useCallback } from 'react';
import * as React from 'react';

interface IProps {
	availabilities: IAvailabilitySlot[];
	breakpoint?: 'mobile' | 'desktop';
	goToStep: (step: number) => () => void;
	hide?: boolean;
	onAvailabilityChange: (availability: IAvailabilitySlot) => void;
	onTimezoneChange: (timezone: ITimezoneData) => void;
	processing?: boolean;
	selectedAvailability?: IAvailabilitySlot;
	selectedDate?: moment.Moment;
	selectedTimezone?: string;
	showPriority?: boolean;
}

export const ScheduleMeetingAvailabilityPicker: React.FC<IProps> = ({
	availabilities = [],
	breakpoint = 'mobile',
	goToStep,
	hide,
	onAvailabilityChange,
	onTimezoneChange,
	processing,
	selectedAvailability,
	selectedDate,
	selectedTimezone,
	showPriority,
}) => {
	const [changingTimezone, , setChangingTimezone] = useLambda(false);

	const onAvailabilityClicked = (availability: IAvailabilitySlot) => () => {
		onAvailabilityChange(availability);
	};

	const onTimeZoneChanged = (tz: ITimezoneData) => {
		setChangingTimezone(false)();
		onTimezoneChange(tz);
	};

	const renderAvailabilities = useCallback(() => {
		if (availabilities.length === 0) {
			return <div className={css(styleSheet.noAvailabilities)}>There are no available times for this date.</div>;
		}

		return availabilities.map((a, i) => {
			let backgroundColor = null;
			if (showPriority) {
				switch (a.priority) {
					// 0 priority is reserved when someone is only able to book for themselves
					// Make these appear as the "best" regardless of their assigned priority.
					case 0:
					case 1:
						backgroundColor = styleSheet.colorPriority1;
						break;
					case 2:
						backgroundColor = styleSheet.colorPriority2;
						break;
					case 3:
						backgroundColor = styleSheet.colorPriority3;
						break;
					case 4:
						backgroundColor = styleSheet.colorPriority4;
						break;
					case 5:
						backgroundColor = styleSheet.colorPriority5;
						break;
					case 6:
					default:
						backgroundColor = styleSheet.colorPriority6;
						break;
				}
			}
			// @ts-ignore
			const availability = a.time.tz(selectedTimezone);
			const isSelected =
				availability.format('MMM DD, YYYY h:mm A') === selectedAvailability?.time?.format('MMM DD, YYYY h:mm A');
			return (
				<div
					className={css(styleSheet.availabilityContainer, isSelected && styleSheet.availabilityContainerSelected)}
					key={i}
				>
					<Button
						className={css(styleSheet.availability, backgroundColor)}
						kind='custom'
						label={availability.format('h:mm A')}
						onClick={onAvailabilityClicked(a)}
					/>
					{isSelected && <CheckmarkIcon className={css(styleSheet.availabilityCheck)} />}
				</div>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availabilities, selectedAvailability, selectedTimezone, onAvailabilityClicked]);

	if (processing) {
		return (
			<div className={css(styleSheet.availabilitiesContainer, hide && pageStyles.hide)}>
				{processing && <LoadingSpinner type='small' className={css(baseStyleSheet.absoluteCenter)} />}
			</div>
		);
	}

	return (
		<div className={css(styleSheet.availabilitiesContainer, hide && pageStyles.hide)}>
			{!selectedDate ? (
				<div className={css(styleSheet.noAvailabilities)}>Select a date to see availabilities.</div>
			) : (
				<div>
					<div className={css(styleSheet.availabilitiesHeader)}>
						<p
							className={css(pageStyles.label, styleSheet.selectedDateString)}
							// @ts-ignore
							onClick={breakpoint === 'mobile' ? goToStep(1) : null}
						>
							{/* @ts-ignore */}
							{getSelectedDateAsString(selectedDate?.tz(selectedTimezone), breakpoint)}
						</p>
						{changingTimezone ? (
							<div className={css(styleSheet.timezonePickerContainer)}>
								<TimezonePicker className='schedule-meeting-timezone-picker' onTimezoneSelected={onTimeZoneChanged} />
								<button
									className={`change-timezone-button ${css(baseStyleSheet.link, styleSheet.link)}`}
									onClick={setChangingTimezone(false)}
								>
									Cancel
								</button>
							</div>
						) : (
							<div>
								<p className={css(pageStyles.label, styleSheet.timezoneNoEdit)}>
									{`Time zone: ${selectedTimezone}`}
									<button
										className={`change-timezone-button ${css(baseStyleSheet.link)}`}
										onClick={setChangingTimezone(true)}
									>
										Change
									</button>
								</p>
							</div>
						)}
					</div>
					<div className={css(styleSheet.availabilities)}>{renderAvailabilities()}</div>
				</div>
			)}
			{showPriority && (
				<div className={css(styleSheet.colorPriorityRow)}>
					<div className={css(styleSheet.colorPriorityText)}>Most to least optimal time</div>
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority1)} />
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority2)} />
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority3)} />
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority4)} />
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority5)} />
					<div className={css(styleSheet.colorPriorityExample, styleSheet.colorPriority6)} />
				</div>
			)}
		</div>
	);
};
