import * as Api from '@ViewModels';
import * as h from 'history';
import { ISetStartDateRequest, ITeamMemberAvailabilities } from '../types';
import { WebServiceHelper } from './webServiceHelper';

export class Meeting {
	// @ts-ignore
	private mShortCode: string;
	// @ts-ignore
	private mTeamMeetingId: string;
	private webServiceHelper: WebServiceHelper;
	private meeting: Api.ISchedulerResponse;
	private setMeeting: React.Dispatch<React.SetStateAction<Api.ISchedulerResponse>>;
	private allowRetry = true;
	private mContactId = '';
	private mOriginalMeetingId = '';

	constructor(
		meeting: Api.ISchedulerResponse,
		setMeeting: React.Dispatch<React.SetStateAction<Api.ISchedulerResponse>>,
		location: h.Location
	) {
		this.setMeeting = setMeeting;
		this.webServiceHelper = new WebServiceHelper();
		const urlParts = location?.pathname?.split('/');
		if (urlParts.length > 2) {
			if (urlParts[1] === 'team') {
				this.mTeamMeetingId = urlParts[2];
			} else {
				this.mShortCode = urlParts[1];
			}
		}
		this.meeting = meeting;
		if (location?.search) {
			const params = new URLSearchParams(location.search);
			this.mContactId = params.get('contactId') || '';
			this.mOriginalMeetingId = params.get('originalMeetingId') || '';
		}
	}

	get meetingData() {
		return this.meeting;
	}

	get availabilities() {
		return this.meeting?.availabilities;
	}

	get baseUrl() {
		return this.webServiceHelper?.baseUrl;
	}

	get company() {
		return this.meeting?.company;
	}

	get duration() {
		return this.meeting?.duration;
	}

	get guestParticipants() {
		if (this.teamMeetingId) {
			return this.meeting?.contacts?.filter((_, i) => i !== 0);
		}
		return this.meeting?.contacts?.filter(c => c.isOptional);
	}

	get host() {
		return this.meeting?.host;
	}

	get id() {
		return this.meeting?.id;
	}

	get locationConfig() {
		return this.meeting?.location;
	}

	get mainParticipant() {
		return this.meeting?.contacts?.find(c => !c.isOptional);
	}

	get minStartDate() {
		return this.meeting?.minimumStartDate;
	}

	get maxStartDate() {
		return this.meeting?.maximumEndDate;
	}

	get name() {
		return this.meeting?.name;
	}

	get shortCode() {
		return this.mShortCode;
	}

	get teamMeetingId() {
		return this.mTeamMeetingId;
	}

	get startDate() {
		return this.meeting?.startDate;
	}

	get allowSameDay() {
		if (this.meeting?.minimumStartDate) {
			const startDate = new Date(this.meeting.minimumStartDate);
			const today = new Date();
			return startDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
		}
		return false;
	}

	get maxPerDay() {
		return this.meeting?.maxPerDay;
	}

	get meetingConfigs() {
		return this.meeting?.meetingConfigs;
	}

	get contacts() {
		return this.meeting?.contacts;
	}

	get title() {
		return this.meeting?.title;
	}

	get accountType() {
		return this.meeting?.accountType;
	}

	get canEditTitle() {
		return this.meeting?.canEditTitle;
	}

	get requireCompanyOnMainParticipant() {
		return this.meeting?.requireCompanyOnMainParticipant;
	}

	get showTextOptIn() {
		return this.meeting?.showTextOptIn;
	}

	get originalMeetingId() {
		return this.mOriginalMeetingId;
	}

	get isReschedule() {
		return !!this.mOriginalMeetingId;
	}

	get commentsLabel() {
		return this.meeting?.commentsLabel;
	}

	get commentsRequired() {
		return this.meeting?.commentsRequired;
	}

	get contactFormFields() {
		return this.meeting?.contactFormFields;
	}

	public altRequestMeeting(userInfo: Api.ISchedulerContact) {
		return this.callWebServiceAsync(`scheduler/${this.id}/requestother`, 'POST', {
			alternativeTime: userInfo.alternativeTime,
			contact: userInfo,
		});
	}

	public reset() {
		this.meeting = null;
		this.setMeeting(this.meeting);
		this.mShortCode = null;
	}

	public scheduleMeeting() {
		return this.callWebServiceAsync(`scheduler/${this.id}/commit`, 'POST', {});
	}

	public updateTitle(title: string) {
		return this.callWebServiceAsync(`scheduler/${this.id}/title?title=${encodeURIComponent(title)}`, 'PUT');
	}

	public updateNotes(notes: string) {
		return this.callWebServiceAsync(`scheduler/${this.id}/comments`, 'PUT', { Comments: notes });
	}

	/**
	 * Retrieves a given month's available days. Will only execute if the meeting id has been retrieved. Will wait for up
	 * to 5 seconds for the id...if not found in that time, will timeout.
	 *
	 * @param {number} monthOffset The month offset to retrieve availabilities for (0 === this month, 1 === next month,
	 *   etc.)
	 * @param {number} count The number of times this method has been called recursively
	 * @param {number} maxCount The max number of times to allow this method to be called recursively
	 * @param {(value: any) => void} res The original promise's resolve method
	 * @param {(err: Api.IOperationResultNoValue) => void} rej The origin promise's reject method
	 * @returns Promise<void>
	 */
	public getAvailabilities(
		monthOffset = 0,
		count = 0,
		maxCount = 100,
		res?: (value: any) => void,
		rej?: (err: Api.IOperationResultNoValue) => void
	) {
		return new Promise((resolve, reject) => {
			const origReject = rej || reject;
			const origResolve = res || resolve;

			if (count >= maxCount) {
				origReject(Api.asApiError(new Error('Request to get availabilities timed out.')));
			} else if (!this.id && this.allowRetry) {
				// usually indicates this.init is still waiting for a response...
				setTimeout(() => {
					count += 1;
					this.getAvailabilities(monthOffset, count, maxCount, origResolve, origReject);
				}, 50);
			} else if (this.id) {
				this.webServiceHelper
					.fetch<Api.ISchedulerDailyIntervals[][]>(
						`scheduler/${this.id}/availability?monthOffset=${monthOffset}`,
						'GET'
					)
					.then(result => {
						if (result.success) {
							this.meeting = {
								...this.meeting,
								availabilities: result.value,
							};
							this.setMeeting(this.meeting);
							origResolve(true);
						} else {
							origReject(Api.asApiError(result));
						}
					})
					.then(err => {
						origReject(Api.asApiError(err));
					});
			}
		});
	}

	public async getTeamAvailabilities(
		offset: number,
		config: Api.IMeetingConfigPriority
	): Promise<ITeamMemberAvailabilities> {
		const result = await this.webServiceHelper.fetch<Api.ISchedulerDailyIntervals[][]>(
			`scheduler/${this.id}/availability/${config.id}?monthOffset=${offset}`,
			'GET'
		);
		if (result.success) {
			// @ts-ignore
			return { availabilities: result.value, config, success: true };
		}
		// @ts-ignore
		return { availabilities: null, config, success: false };
	}

	public async init(timezone: string) {
		if (!!this.shortCode || !!this.teamMeetingId) {
			const contactIdParam = this.mContactId ? `&contactId=${this.mContactId}` : '';
			const originalMeetingIdParam = this.mOriginalMeetingId ? `&originalMeetingId=${this.mOriginalMeetingId}` : '';
			const url = `scheduler/${this.teamMeetingId || this.shortCode}?timeZoneId=${encodeURIComponent(
				timezone
			)}${contactIdParam}${originalMeetingIdParam}`;
			try {
				const result = await this.webServiceHelper.fetch<Api.ISchedulerResponse>(
					url,
					this.teamMeetingId ? 'GET' : 'POST'
				);
				if (result.success) {
					// @ts-ignore
					this.meeting = result.value;
					// @ts-ignore
					this.setMeeting(result.value);
					this.allowRetry = true;
				} else {
					this.allowRetry = false;
					throw Api.asApiError(result);
				}
			} catch (err) {
				this.allowRetry = false;
				throw Api.asApiError(err);
			}
		}
	}

	public createNewGuestParticipant(emailAddress: string) {
		return this.callWebServiceAsync(`scheduler/${this.id}/contact`, 'POST', {
			emailAddress,
			isOptional: true,
		});
	}

	public removeGuestParticipant(guest: Api.ISchedulerContact) {
		return this.callWebServiceAsync(`scheduler/${this.id}/contact/${guest.id}`, 'DELETE');
	}

	public setDateTime(startDate: Date, configIds?: string[]) {
		if (this.teamMeetingId) {
			// @ts-ignore
			return this.setDateTimeV2(startDate, configIds);
		}
		return this.setDateTimeV1(startDate);
	}

	private setDateTimeV1(startDate: Date) {
		return this.callWebServiceAsync(
			`scheduler/${this.id}/startdate?startDate=${encodeURIComponent(startDate.toUTCString())}`,
			'PUT'
		);
	}

	private setDateTimeV2(startDate: Date, configIds: string[]) {
		const request: ISetStartDateRequest = {
			configIds,
			startDate: startDate.toUTCString(),
		};
		return this.callWebServiceAsync(`scheduler/${this.id}/startdate/v2`, 'PUT', request);
	}

	public setMainParticipant(participant: Api.ISchedulerContact) {
		// @ts-ignore
		return this.callWebServiceAsync(`scheduler/${this.id}/contact/${this.mainParticipant.id}`, 'PUT', participant);
	}

	public updateLocationPhone(location: Api.IPhoneMeetingLocation) {
		return this.callWebServiceAsync(`scheduler/${this.id}/location/phone`, 'POST', location);
	}

	public updateLocationInPerson(location: Api.IInPersonMeetingLocation) {
		return this.callWebServiceAsync(`scheduler/${this.id}/location/inPerson`, 'POST', location);
	}

	public updateLocationVirtual(location: Api.IVirtualMeetingLocation) {
		return this.callWebServiceAsync(`scheduler/${this.id}/location/virtual`, 'POST', location);
	}

	private async callWebServiceAsync(url: string, method: Api.HTTPMethod, body?: any) {
		try {
			const result = await this.webServiceHelper.fetch<Api.ISchedulerResponse>(url, method, body);
			if (result.success) {
				this.meeting = {
					...this.meeting,
					...result.value,
				};
				this.setMeeting(this.meeting);
			} else {
				throw Api.asApiError(result);
			}
		} catch (err) {
			throw Api.asApiError(err);
		}
	}
}
