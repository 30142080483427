import { ScheduleMeetingErrorModal } from '../ScheduleMeetingErrorModal';
import { IErrorContext } from '../types';
import * as Api from '@ViewModels';
import { createContext, useCallback, useState } from 'react';
import * as React from 'react';

// @ts-ignore
const ErrorContext = createContext<IErrorContext>(null);

export const ErrorProvider: React.FC = ({ children }) => {
	// @ts-ignore
	const [error, setError] = useState<Api.IOperationResultNoValue>(undefined);

	const onClose = useCallback(() => {
		// @ts-ignore
		setError(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	return (
		<ErrorContext.Provider value={{ setError }}>
			{error && <ScheduleMeetingErrorModal error={error} onClose={onClose} />}
			{children}
		</ErrorContext.Provider>
	);
};

export const useErrorMessage = () => {
	const context = React.useContext(ErrorContext);
	if (!context) {
		throw new Error('userError can only be used within the <ScheduleMeetingApp /> component');
	}
	return context;
};
