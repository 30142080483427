import { StyleSheet } from 'aphrodite';
import { black, midGrey } from '../../web/styles/colors';

export const styleSheet = StyleSheet.create({
	optInCheckboxContainer: {
		marginBottom: 8,
	},
	optInCheckbox: {
		color: black,
		marginBottom: 8,
	},
	optInCheckboxDisclaimer: {
		color: midGrey,
		fontSize: 10,
	},
	userInfoItem: {
		paddingBottom: 15,
	},
});
