import { render as DOMRender } from 'react-dom';
import { polyfillAsync } from '../models/UiUtils';
import { ScheduleMeetingRouter } from './ScheduleMeetingRouter';
import { ErrorProvider } from './contexts/errorMessage';
import './styles/global-styles.less';

document.addEventListener('DOMContentLoaded', async () => {
	await polyfillAsync();
	const rootElement = document.getElementById('react-root');

	DOMRender(
		<ErrorProvider>
			<ScheduleMeetingRouter />
		</ErrorProvider>,
		rootElement
	);
});
