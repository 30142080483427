import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useCallback } from 'react';
import { LoadingSpinner } from '../../web/components/LoadingSpinner';
import { ClockIcon } from '../../web/components/svgs/icons/ClockIcon';
import { LocationPinIcon } from '../../web/components/svgs/icons/LocationPinIcon';
import { grayIconFill } from '../../web/styles/colors';
import { baseStyleSheet } from '../../web/styles/styles';
import { ScheduleMeetingHeaderHost } from '../ScheduleMeetingHeaderHost';
import { getDurationText } from '../UiUtils';
import { useMeeting } from '../contexts/meeting';
import { styleSheet as pageStyles } from '../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	fetching?: boolean;
}

export const ScheduleMeetingHeader = ({ fetching }: IProps) => {
	const { meetingVm } = useMeeting();
	const renderDuration = useCallback(() => {
		// @ts-ignore
		return getDurationText(meetingVm.duration);
	}, [meetingVm.duration]);

	const renderLocation = useCallback(() => {
		// @ts-ignore
		switch (meetingVm.locationConfig._type) {
			case Api.MeetingType.Phone:
				return 'I will give you a call.';
			case Api.MeetingType.InPerson:
				return (meetingVm.locationConfig as Api.IInPersonLocationConfig).address;
			case Api.MeetingType.Virtual:
				return 'Virtual (link to be provided upon meeting booking)';
			case Api.MeetingType.AllowInvitee:
				return 'Attendee to choose';
			case Api.MeetingType.Other:
				return (meetingVm.locationConfig as Api.IOtherMeetingLocationConfig).instructions;
			default:
				return '';
		}
	}, [meetingVm.locationConfig]);

	return (
		<div className={css(pageStyles.header, !fetching && !meetingVm.id && baseStyleSheet.flexCenter)}>
			{fetching && <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />}
			{!fetching && !meetingVm.id && <p className={css(styleSheet.errorMessage)}>An error has occurred.</p>}
			{!fetching && meetingVm.id && (
				<>
					<div className={css(pageStyles.headerCol, pageStyles.headerColLeft)}>
						<ScheduleMeetingHeaderHost
							host={meetingVm.host}
							company={meetingVm.company}
							isReschedule={meetingVm.isReschedule}
						/>
					</div>
					<div className={css(pageStyles.headerCol, pageStyles.headerColRight)}>
						<p className={css(styleSheet.meetingName)}>{meetingVm.name}</p>
						<p className={css(pageStyles.label, styleSheet.labelWithIcon)}>
							<ClockIcon className={css(styleSheet.labelIcon)} fillColor={grayIconFill} />
							{renderDuration()}
						</p>
						<p
							className={css(
								pageStyles.label,
								styleSheet.labelWithIcon,
								meetingVm.locationConfig._type === Api.MeetingType.InPerson ? pageStyles.labelAddress : undefined
							)}
						>
							<LocationPinIcon className={css(styleSheet.labelIcon)} fillColor={grayIconFill} />
							{renderLocation()}
						</p>
					</div>
				</>
			)}
		</div>
	);
};
