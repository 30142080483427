import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { DeprecatedXIcon } from '../../web/components/svgs/icons/DeprecatedXIcon';
import { baseStyleSheet } from '../../web/styles/styles';
import { ScheduleMeetingErrorIcon } from '../icons/ScheduleMeetingErrorIcon';
import { styleSheet } from './styles';

interface IProps {
	error: Api.IOperationResultNoValue;
	onClose: () => void;
	title?: string;
}

/*
 * using this error modal instead of the usual one because ErrorMessageModal
 * uses mobx. We are not using mobx on the new schedule meeting page in
 * an effort to continue to provide support for IE after we upgrade mobx to a
 * version that no longer does.
 */
export const ScheduleMeetingErrorModal: React.FC<IProps> = ({ error, onClose, title = 'Error' }) => {
	return (
		<div className={css(styleSheet.container, baseStyleSheet.absoluteCenter)}>
			<button className={css(styleSheet.closeButton)} onClick={onClose}>
				<DeprecatedXIcon />
			</button>
			<div>
				<ScheduleMeetingErrorIcon className={css(styleSheet.icon)} />
			</div>
			<div className={css(styleSheet.title)}>{title}</div>
			<div className={css(styleSheet.message)}>{error.systemMessage}</div>
			<button className={css(baseStyleSheet.ctaButton)} onClick={onClose}>
				Got it
			</button>
		</div>
	);
};
